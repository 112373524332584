// @ts-strict-ignore
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { throttle } from 'lodash';
import { observer } from 'mobx-react';
import FlipMove from 'react-flip-move';

import { useStores } from 'mobx/hooks/useStores';

import { getOutcomeClassByOutcome } from 'utils/pathwaysUtils';
import { isInViewport, isScrolledOutOfView, isScrolledToBottom } from 'utils/ViewUtils';

import {
  PathwayBasicInfo,
  PathwayOptionOutcome,
  PathwayQuestionCategory,
  PathwayTemplate,
  PathwayTemplateQuestion
} from 'models/PathwayTemplates';

import { DefaultBanner, PointBasedBanner } from 'views/Patient/PatientMain/PathwayBanner';

import Icon from 'components/Icons/Icon';
import { Text } from 'components/UIkit/atoms/Text';

import PathwayQuestionView from './PathwayQuestionView';

import './PathwayQuestionsList.scss';

interface IPathwayQuestionsListProps {
  pathway: PathwayTemplate;
  filterKeyQuestions: boolean;
  selectedQuestionId?: string;
  onCollapseClicked: () => void;
  handlePathwaySelected: (selectedPathway: PathwayBasicInfo) => void;
}

const Arrow: FC<{ isUp?: boolean; arrowClass: string; count: number }> = ({
  isUp = false,
  arrowClass,
  count
}) => {
  const displayCount = count > 0 ? count : 1; // make sure 0 is not visible on fadeOut
  const containerClass = `arrow-container-${isUp ? 'above' : 'below'}`;
  const wrapperClasses = classNames('warning-count-arrow-container', containerClass, {
    show: count > 0
  });

  return (
    <div className={wrapperClasses}>
      <div className={classNames('warning-count-arrow', arrowClass)}>
        <span className="counter">{displayCount}</span>
        <Icon.DoubleArrow up={isUp} />
      </div>
    </div>
  );
};

export const PathwayQuestionsList = observer((props: IPathwayQuestionsListProps) => {
  const { pathwaysStore } = useStores();
  const [unseenWarningAbove, setUnseenWarningAbove] = useState(0);
  const [unseenWarningBelow, setUnseenWarningBelow] = useState(0);
  const listContainerRef = useRef(null);
  const refs = useRef<Record<string, HTMLDivElement>>({});
  const { pathway } = props;

  const getFilteredQuestions = (questions: PathwayTemplateQuestion[]) => {
    return questions.filter((question: PathwayTemplateQuestion) => {
      if (question.dependsOn) {
        return false;
      }
      return props.filterKeyQuestions ? question.isKey : question;
    });
  };

  const filteredQuestions = getFilteredQuestions(
    pathwaysStore.getAllQuestionsForPathway(props.pathway.id)
  );

  useEffect(
    function scrollToElem() {
      if (refs.current[props.selectedQuestionId]) {
        refs.current[props.selectedQuestionId].scrollIntoView({ behavior: 'smooth' });
      }
    },
    [props.selectedQuestionId]
  );

  const calculateAndSetUnseenWarningCount = useCallback(() => {
    const questions: PathwayTemplateQuestion[] = pathwaysStore.getAllQuestionsForPathway(
      props.pathway.id
    );

    let countAbove = 0;
    let countBelow = 0;
    questions.forEach((question) => {
      if (
        pathwaysStore.getOutcomeForQuestionOnCurrentPathway(question) >=
          PathwayOptionOutcome.CLINIC &&
        refs.current[question.id]
      ) {
        const isOutOfView = isScrolledOutOfView(
          refs.current[question.id],
          listContainerRef.current
        );
        if (isOutOfView.isAbove) {
          countAbove++;
        }
        if (isOutOfView.isBelow) {
          countBelow++;
        }
      }
    });

    setUnseenWarningAbove(countAbove);
    setUnseenWarningBelow(countBelow);
  }, [pathwaysStore, props.pathway.id]);

  const setCurrentHighlightedQuestion = useCallback(() => {
    const questionsInViewport = filteredQuestions.filter((question) =>
      isInViewport(refs.current[question.id])
    );
    const unansweredQuestionsInViewport = questionsInViewport.filter(
      (question) => !pathwaysStore.isQuestionAnswered(question.id)
    );
    if (questionsInViewport.length > 0) {
      const lastQuestion = filteredQuestions[filteredQuestions.length - 1];
      const lastElementRef = refs.current[filteredQuestions[filteredQuestions.length - 1].id];
      const isLastQuestionAtBottom = isScrolledToBottom(lastElementRef, listContainerRef.current);
      const highlightedQuestion =
        isLastQuestionAtBottom && unansweredQuestionsInViewport.length === 0
          ? lastQuestion
          : unansweredQuestionsInViewport.length > 0 && isLastQuestionAtBottom
          ? unansweredQuestionsInViewport[0]
          : questionsInViewport[0];
      pathwaysStore.setCurrentHighlightedQuestionId(highlightedQuestion.id);
    }
  }, [filteredQuestions, pathwaysStore]);

  useEffect(() => {
    listContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathwaysStore.currentPathwayInfo.id]);

  useEffect(
    function handleFilteredQuestionChanges() {
      calculateAndSetUnseenWarningCount();
      setCurrentHighlightedQuestion();
    },
    [calculateAndSetUnseenWarningCount, setCurrentHighlightedQuestion]
  );

  const throttledOnScroll = useMemo(
    () =>
      throttle(() => {
        calculateAndSetUnseenWarningCount();
        setCurrentHighlightedQuestion();
      }, 50),
    [calculateAndSetUnseenWarningCount, setCurrentHighlightedQuestion]
  );

  const isDecreasedScorePathway = pathwaysStore.getIsDecreasedScorePathway(pathway.id);

  const renderDependentQuestions = (questions: PathwayTemplateQuestion[]): ReactNode => {
    return questions.map((followUpQuestion: PathwayTemplateQuestion) => {
      let dependentQuestions = pathwaysStore.getDependentQuestions(followUpQuestion);
      return (
        <div key={followUpQuestion.id} className="dependent-question">
          <div ref={(ref: HTMLDivElement) => (refs.current[followUpQuestion.id] = ref)}>
            <PathwayQuestionView
              question={followUpQuestion}
              hasKeyQuestionsFilter={props.filterKeyQuestions}
              linkToPathway={props.handlePathwaySelected}
              shouldShowScore={!isDecreasedScorePathway}
            />
          </div>
          {dependentQuestions && renderDependentQuestions(dependentQuestions)}
        </div>
      );
    });
  };

  const arrowsClass = getOutcomeClassByOutcome(pathwaysStore.highestCurrentOutcome);

  const getBanner = useCallback(() => {
    if (pathwaysStore.isCurrentPathwayPointBased) {
      return <PointBasedBanner />;
    }

    return <DefaultBanner />;
  }, [pathwaysStore.isCurrentPathwayPointBased]);

  return (
    <div className="pathway-questions-container" data-test-hook={`${pathway.id}-questions-list`}>
      <div
        className="pathway-questions-list"
        ref={(ref) => (listContainerRef.current = ref)}
        onScroll={throttledOnScroll}
      >
        {pathwaysStore.activeCategories.map((category: PathwayQuestionCategory) => {
          let filteredCategoryQuestions = getFilteredQuestions(category.questions);
          return (
            filteredCategoryQuestions &&
            filteredCategoryQuestions.length > 0 && (
              <div
                key={category.id}
                className="category-section"
                data-test-hook={`${category.id}-category-section`}
              >
                <div className="category-header">
                  <h1 className="category-title">{category.title.toUpperCase()}</h1>
                </div>

                {pathwaysStore.isIcansPathway(pathway.id) && (
                  <Text
                    component="div"
                    fontStyle="italic"
                    variant="body1"
                    fontSize="medium"
                    pt={8}
                    pl={40}
                  >
                    Notice: This patient is treated with a bispecific antibody. Recommending CRS And
                    ICANs screening.
                  </Text>
                )}

                <div>
                  {filteredCategoryQuestions.map((question: PathwayTemplateQuestion) => {
                    const followUps = pathwaysStore.getDependentQuestions(question);
                    return (
                      <div className="question-container" key={`${question.id}_container`}>
                        <div
                          className="main-question"
                          ref={(ref: HTMLDivElement) => (refs.current[question.id] = ref)}
                        >
                          <PathwayQuestionView
                            key={question.id}
                            question={question}
                            hasKeyQuestionsFilter={props.filterKeyQuestions}
                            linkToPathway={props.handlePathwaySelected}
                            shouldShowScore={!isDecreasedScorePathway}
                          />
                        </div>
                        <FlipMove
                          enterAnimation="accordionVertical"
                          leaveAnimation="none"
                          typeName={null}
                        >
                          {renderDependentQuestions(followUps)}
                        </FlipMove>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
        <div className="questions-footer">
          <div className="collapse-btn" onClick={() => props.onCollapseClicked()}>
            <span className="mr-3">
              <Icon.DoubleArrow right />
            </span>
            <span>COLLAPSE PATHWAY</span>
          </div>
        </div>
      </div>
      <Arrow isUp arrowClass={arrowsClass} count={unseenWarningAbove} />
      <Arrow arrowClass={arrowsClass} count={unseenWarningBelow} />
      {getBanner()}
    </div>
  );
});
