// @ts-strict-ignore
import { FC, useCallback, useRef, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackActionsMenuAnalyticsEvent } from 'analytics/events/actions-menu';
import { trackPathwayMenuAnalyticsEvent } from 'analytics/events/pathway-menu';
import { trackReassignMenuAnalyticsEvent } from 'analytics/events/reassign-menu';

import { ErrorName } from 'errors';

import { observer } from 'mobx-react';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { TicketTypes } from 'mobx/stores';

import { pluralize } from 'utils/StringUtils';

import { FEATURES } from 'constants/features';

import { PathwaySearchResult } from 'models/PathwayTemplates';

import { TicketStatus, ticketStatusToString } from 'models/Ticket';

import { useBulkTicketAssign } from 'hooks/useBulkTicketAssign';

import { useBulkTicketResolve } from 'hooks/useBulkTicketResolve';
import { useCliniciansTooltipAsyncSelect } from 'hooks/useCliniciansTooltipAsyncSelect';
import { useDoctors } from 'hooks/useDoctor';

import { usePathwayOptions } from 'hooks/usePathwaysOptions';

import { useToggleCallLog } from 'hooks/useToggleCallLog';

import { usePatientModel } from 'components/Patient/usePatientModel';

import { useTicketResolveAndCommentModal } from 'components/Ticket/TicketsContainers/TicketsContainers.shared';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { DividerPlacement, ITooltipOption } from 'components/Tooltip';
import { TooltipController } from 'components/Tooltip/Tooltip.types';
import { BulkMenu } from 'components/UIkit/atoms/BulkMenu';
import { BulkItem } from 'components/UIkit/atoms/BulkMenu/BulkMenu.types';

const TicketsBulkActionMenu: FC = () => {
  const history = useHistory();
  const { allowBulkActions, openDisconnectDraftPopup } = useTicketOverviewContext();

  const bulkTicketResolve = useBulkTicketResolve();
  const bulkAssign = useBulkTicketAssign();
  const patient = usePatientModel();

  const { ticketsStore, userStore, callLoggingStore, tasksStore, settingsStore } = useStores();
  const { currentDoctor } = userStore;
  const [isPathwaysMenuOpen, setIsPathwaysMenuOpen] = useState(false);
  const [pathwaysSearchValue, setPathwaysSearchValue] = useState('');
  const [resolveAndCommentModal, openResolveAndCommentModal] = useTicketResolveAndCommentModal();
  const prevNumOfTicketsRef = useRef<number | undefined>();
  const { selectedDoctor } = useDoctors(ticketsStore.tickets);

  const handleReassign = useCallback(
    async (doctorId: number) => {
      trackReassignMenuAnalyticsEvent({
        action: AnalyticEventAction.Select,
        value: 'list item',
        ticket_ids: ticketsStore.bulkActionTicketIds,
        bulk_action: true
      });

      await bulkAssign(doctorId);
      tasksStore.resetAndFetchTasks();
      ticketsStore.fetchTicketsForPatient(patient.id);
    },
    [tasksStore, ticketsStore, patient.id, bulkAssign]
  );
  const {
    isLoading: isReassignSelectLoading,
    options: reassignOptions,
    onSearchChange: onReassignSearchChange
  } = useCliniciansTooltipAsyncSelect(handleReassign, selectedDoctor?.id);

  const reassignMyselfOption: ITooltipOption = {
    text: 'Myself',
    onClick: () => handleReassign(currentDoctor?.id)
  };

  const formattedReassignOptions = [...reassignOptions];

  if (currentDoctor) {
    formattedReassignOptions.unshift(reassignMyselfOption);
  }

  const handlePathwaySelected = (selectedPathway: PathwaySearchResult) => {
    const ticketIds = ticketsStore.nonTaskTicketIdsBulkAction;
    const taskIds = ticketsStore.taskTicketIdsBulkAction;
    if (taskIds.length > 1) {
      throw new Error('Only single task can be connected to a call.');
    }
    setIsPathwaysMenuOpen(false);
    callLoggingStore.startCallSession({
      selectedPathway,
      forTicketIds: ticketIds,
      connectedTaskId: taskIds.length ? taskIds[0] : null
    });

    ticketsStore.resetTicketsBulkActionSet();

    if (!history.location.pathname.includes('patient')) {
      history.push(`/patient/${ticketsStore.ticketsBulkPatientId}`);
    }
  };

  const pathwaysMenuController: TooltipController = {
    visible: isPathwaysMenuOpen,
    onClickOutside: () => setIsPathwaysMenuOpen(false)
  };

  const pathwaysOptions: ITooltipOption[] = usePathwayOptions(
    {
      tooltipController: pathwaysMenuController,
      allowNoPathway: true,
      onSelect: handlePathwaySelected,
      showSelected: true,
      patientId: ticketsStore.ticketsBulkPatientId,
      ticketIds: Array.from(ticketsStore.ticketsBulkActionSet)
    },
    pathwaysSearchValue,
    true
  );
  const isLogCallPathwaysFeatureEnabled = settingsStore.hasFeature(
    FEATURES.ACTIONS_LOG_CALLS_PATHWAYS
  );
  const isLogCallFromTaskEnabled = settingsStore.hasFeature(FEATURES.ACTIONS_LOG_CALL_FROM_TASK);

  const handleLogCallClicked = useToggleCallLog(() => {
    if (!isLogCallPathwaysFeatureEnabled) {
      handlePathwaySelected(null);
      return;
    }

    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Click,
      ticket_id: ticketsStore.bulkActionTicketIds,
      value: 'Log Call',
      bulk_action: true
    });
    setIsPathwaysMenuOpen((prevState) => !prevState);
  });

  const numOfTickets = ticketsStore.ticketsBulkActionSet.size;
  const show = numOfTickets > 0;
  // for gracefull display on fadout (show previous numOfTickets)
  const displayNumOfTickets = show ? numOfTickets : prevNumOfTicketsRef.current;
  const ticketsCountSuffix = pluralize('Item', displayNumOfTickets);
  prevNumOfTicketsRef.current = numOfTickets || prevNumOfTicketsRef.current;
  const selectedTicketTypes = ticketsStore.bulkSelectedTicketsTicketTypes;
  const hasSingleTask =
    ticketsStore.bulkActionTickets.filter((ticket) => ticket.isTask).length === 1;

  const areOnlyTasksSelected =
    selectedTicketTypes.size === 1 && selectedTicketTypes.has(TicketTypes.task);

  const areOnlyTicketsSelected =
    selectedTicketTypes.size === 1 && selectedTicketTypes.has(TicketTypes.ticket);

  const handleResolve = async ({ withComment }: { withComment: boolean }) => {
    const { bulkActionTicketIds: ticketIds } = ticketsStore;
    if (withComment) {
      openResolveAndCommentModal(ticketIds);
    } else {
      try {
        await bulkTicketResolve(ticketIds);
      } catch (error) {
        if (error.name === ErrorName.TicketConnectedToDraft) {
          openDisconnectDraftPopup({
            ticketIds,
            callback: async () => {
              await bulkTicketResolve(ticketIds);
            }
          });
        } else throw error;
      }
    }
  };

  const handleStatusChange = async (newStatus: TicketStatus) => {
    await tasksStore.bulkStatusChange(ticketsStore.bulkActionTickets, newStatus);
    tasksStore.fetchTasks();
    ticketsStore.resetTicketsBulkActionSet();
  };

  const handleReschedule = async (date: Date) => {
    trackActionsMenuAnalyticsEvent({
      action: AnalyticEventAction.Select,
      bulk_action: true,
      value: 'Reschedule',
      ticket_id: ticketsStore.bulkActionTicketIds
    });
    await tasksStore.bulkReschedule(ticketsStore.bulkActionTickets, date);
    tasksStore.fetchTasks();
    ticketsStore.resetTicketsBulkActionSet();
  };

  if (!allowBulkActions) {
    return null;
  }

  const resolveSelectOptions: ITooltipOption[] = [
    {
      text: 'Resolve',
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Resolve',
          bulk_action: true
        });
        handleResolve({ withComment: false });
      }
    },
    {
      text: 'Resolve & Comment',
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Resolve And Comment',
          bulk_action: true
        });
        handleResolve({ withComment: true });
      }
    }
  ];

  const changeStatusOptions: ITooltipOption[] = [
    {
      text: ticketStatusToString[TicketStatus.OPEN],
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'To Do',
          bulk_action: true
        });
        handleStatusChange(TicketStatus.OPEN);
      }
    },
    {
      text: ticketStatusToString[TicketStatus.IN_PROGRESS],
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'In Progress',
          bulk_action: true
        });
        handleStatusChange(TicketStatus.IN_PROGRESS);
      },
      withDivider: true,
      dividerPlacement: DividerPlacement.Bottom
    },
    {
      text: ticketStatusToString[TicketStatus.CLOSED],
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Closed',
          bulk_action: true
        });
        handleStatusChange(TicketStatus.CLOSED);
      }
    },
    {
      text: ticketStatusToString[TicketStatus.RESOLVED],
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Resolved',
          bulk_action: true
        });
        handleResolve({ withComment: false });
      }
    },
    {
      text: 'Resolve & Comment',
      onClick: () => {
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Resolve And Comment',
          bulk_action: true
        });
        handleResolve({ withComment: true });
      }
    }
  ];

  const logCallBulkMenuItem: BulkItem = {
    controller: pathwaysMenuController,
    disabledText: 'Only tickets can be connected to a call.',
    isDisabled: !areOnlyTicketsSelected && (!isLogCallFromTaskEnabled || !hasSingleTask),
    textButtonProps: {
      onClick: handleLogCallClicked,
      disabled:
        !ticketsStore.ticketsBulkPatientId ||
        (!areOnlyTicketsSelected && (!isLogCallFromTaskEnabled || !hasSingleTask)),
      label: 'Log Call'
    },
    content: {
      type: 'select',
      options: pathwaysOptions,
      onSearchChange: setPathwaysSearchValue,
      onSearchBlur: () => {
        trackPathwayMenuAnalyticsEvent({
          action: AnalyticEventAction.Search,
          virtual_page: 'call logger',
          tab: undefined
        });
      },
      searchPlaceholder: 'Search Pathways',
      searchValue: pathwaysSearchValue
    }
  };

  const reassignBulkMenuItem: BulkItem = {
    placement: 'top',
    textButtonProps: {
      label: 'Reassign',
      onClick: () =>
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Click,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Reassign',
          bulk_action: true
        })
    },
    content: {
      type: 'async-select',
      options: formattedReassignOptions,
      isLoading: isReassignSelectLoading,
      onSearchChange: onReassignSearchChange,
      searchPlaceholder: 'Search Staff'
    }
  };

  const resolveBulkMenuItem: BulkItem = {
    placement: 'top',
    textButtonProps: {
      label: 'Resolve',
      onClick: () =>
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Click,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Resolve',
          bulk_action: true
        })
    },
    content: {
      type: 'select',
      options: resolveSelectOptions
    }
  };

  const changeStatusBulkMenuItem: BulkItem = {
    placement: 'top',
    disabledText: 'Only tasks can change status.',
    isDisabled: !areOnlyTasksSelected,
    textButtonProps: {
      disabled: !areOnlyTasksSelected,
      label: 'Change Status',
      onClick: () =>
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Click,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Change Status',
          bulk_action: true
        })
    },
    content: {
      type: 'select',
      options: changeStatusOptions
    }
  };

  const rescheduleBulkMenuItem: BulkItem = {
    disabledText: 'Only tasks can be rescheduled.',
    isDisabled: !areOnlyTasksSelected,
    textButtonProps: {
      disabled: !areOnlyTasksSelected,
      label: 'Reschedule',
      onClick: () =>
        trackActionsMenuAnalyticsEvent({
          action: AnalyticEventAction.Click,
          ticket_id: ticketsStore.bulkActionTicketIds,
          value: 'Reschedule',
          bulk_action: true
        })
    },
    content: {
      type: 'date',
      onSelect: handleReschedule
    }
  };

  return (
    <>
      {resolveAndCommentModal}

      <BulkMenu
        isOpen={show}
        summaryText={`${displayNumOfTickets} ${ticketsCountSuffix}`}
        items={[
          logCallBulkMenuItem,
          reassignBulkMenuItem,
          resolveBulkMenuItem,
          changeStatusBulkMenuItem,
          rescheduleBulkMenuItem
        ]}
      />
    </>
  );
};

export default observer(TicketsBulkActionMenu);
