import { FC, useMemo } from 'react';

import { Box, Theme } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { AiMessageSuggestion } from 'fetchers/TwoWayMessagesFetcher';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  suggestion?: AiMessageSuggestion;
  isLoading?: boolean;
  isSelected?: boolean;
  isError?: boolean;
  height?: number;
  onClick?: (suggestion: AiMessageSuggestion) => void;
  onTryAgain?: () => void;
  errorType?: 'failed' | 'retry';
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const AiSuggestionCard: FC<Props> = ({
  suggestion,
  isLoading,
  isSelected,
  isError = false,
  onClick,
  onTryAgain,
  errorType = 'failed',
  onMouseEnter,
  onMouseLeave
}) => {
  const handleClick = () => {
    if (isLoading || isError) {
      if (isError && errorType === 'retry' && onTryAgain) {
        onTryAgain();
      }
      return;
    }

    if (suggestion && onClick) {
      onClick(suggestion);
    }
  };

  const cardContent = useMemo(() => {
    if (isLoading) {
      return <AiSuggestionLoader />;
    }

    if (isError) {
      return (
        <StyledErrorText variant="body1" color={errorType === 'failed' ? 'secondary' : 'dark'}>
          {errorType === 'failed' ? 'Error Loading AI Suggestions' : 'Try Again'}
        </StyledErrorText>
      );
    }

    return (
      <StyledSuggestionText variant="body1" color="secondary" maxNumberOfLines={3}>
        {suggestion?.suggestionText}
      </StyledSuggestionText>
    );
  }, [isLoading, isError, errorType, suggestion?.suggestionText]);

  return (
    <StyledCard
      onClick={handleClick}
      {...(!isLoading && !isError && onMouseEnter ? { onMouseEnter } : {})}
      {...(!isLoading && !isError && onMouseLeave ? { onMouseLeave } : {})}
      isSelected={isSelected}
      isLoading={isLoading}
      isError={isError}
      isRetry={errorType === 'retry'}
      tabIndex={-1}
      onMouseDown={(e) => e.preventDefault()}
    >
      {cardContent}
    </StyledCard>
  );
};

const getBackgroundColors = (
  theme: Theme,
  {
    isLoading,
    isSelected,
    isError
  }: { isLoading?: boolean; isSelected?: boolean; isError?: boolean }
) => {
  if (isLoading) {
    return {
      main: theme.palette.natural.contrast,
      hover: null
    };
  }

  if (isSelected) {
    return {
      main: theme.palette.primary.contrastText,
      hover: theme.palette.primary.contrastText
    };
  }

  if (isError) {
    return {
      main: theme.palette.secondary.alternate,
      hover: theme.palette.secondary.alternate
    };
  }

  return { main: theme.palette.secondary.alternate, hover: theme.palette.secondary.light };
};

const AiSuggestionLoader: FC = () => {
  return (
    <StyledLoaderContainer>
      <StyledLoaderContent />
      <StyledLoaderContent />
      <StyledLoaderContent />
    </StyledLoaderContainer>
  );
};

const StyledCard = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isSelected', 'isLoading', 'isError', 'isRetry'].includes(prop as string)
})<{
  isSelected?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isRetry: boolean;
}>(({ theme, isSelected, isLoading, isError }) => {
  const { main: bgMainColor, hover: bgHoverColor } = getBackgroundColors(theme, {
    isLoading,
    isError,
    isSelected
  });

  let cursor = isLoading || isSelected ? 'default' : 'pointer';

  return css`
    padding: ${theme.spacing(8)};
    border-radius: ${theme.borderRadius.large};
    background-color: ${bgMainColor};
    cursor: ${cursor};
    overflow: hidden;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ${isError &&
    css`
      align-items: center;
      justify-content: center;
    `}

    border: none;
    min-height: fit-content;

    &:hover {
      background-color: ${bgHoverColor};
    }
  `;
});

const StyledSuggestionText = styled(Text)`
  width: 100%;
`;

const StyledErrorText = styled(Text)`
  text-align: center;
`;

const StyledLoaderContainer = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `
);

const StyledLoaderContent = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 12px;
    border-radius: ${theme.borderRadius.small};
    background: linear-gradient(
      to right,
      ${theme.palette.natural.inactiveBackground} 8%,
      #ebebeb 18%,
      ${theme.palette.natural.inactiveBackground} 33%
    );
    background-size: 800px 104px;
    animation: shimmerEffect 3s ease-in-out infinite;

    @keyframes shimmerEffect {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  `
);
