// @ts-strict-ignore
import { RouterHistory } from '@sentry/react/build/types/reactrouter';
import { useLocation } from 'react-router-dom';

import {
  FIRST_PAGE,
  PAGE_PARAM,
  RECORDS_PER_PAGE_PARAM,
  SORT_ASC_PARAM,
  SORT_BY_PARAM
} from 'constants/urlParams.const';

export const urlPages: { [key: string]: string } = {
  WORK_QUEUE: 'work-queue',
  HOME: 'home',
  TRIAGE: 'triage',
  CARE_MANAGEMENT: 'care-management',
  CREATE_TICKET: 'create-tickets',
  PATIENT_PAGE: 'patient',
  PATIENT_LIST: 'patient-list',
  INVITE: 'invitation',
  AWAITING_ACTIVATION: 'activation',
  TRIAGE_RULES: 'alerts',
  PRACTICE_MANAGER: 'practice',
  PRACTICE_ANALYTICS: 'practice-analytics',
  PATHWAY_BUILDER: 'pathway-builder',
  PATIENT_REGISTRATION: 'patient-registration'
};

export const urlPageToPageName = {
  [urlPages.WORK_QUEUE]: 'work queue',
  [urlPages.HOME]: 'home',
  [urlPages.TRIAGE]: 'work queue',
  [urlPages.CARE_MANAGEMENT]: 'care management',
  [urlPages.CREATE_TICKET]: 'create ticket',
  [urlPages.PATIENT_PAGE]: 'patient page',
  [urlPages.PATIENT_LIST]: 'patient list',
  [urlPages.INVITE]: 'invite patient',
  [urlPages.AWAITING_ACTIVATION]: 'awaiting activation',
  [urlPages.TRIAGE_RULES]: 'triage rules',
  [urlPages.PRACTICE_MANAGER]: 'practice manager',
  [urlPages.PRACTICE_ANALYTICS]: 'practice analytics',
  [urlPages.PATHWAY_BUILDER]: 'pathway builder',
  [urlPages.PATIENT_REGISTRATION]: 'patient registration'
};

export enum PatientPageTab {
  OpenTickets = 'open_tickets',
  ResolvedTickets = 'resolved_tickets',
  Calls = 'calls',
  EpisodesAndTasks = 'episodes_and_tasks'
}

export enum PracticeManagerTab {
  Users = 'users',
  TicketTypes = 'ticketTypes',
  Episodes = 'episodes',
  Tags = 'tags',
  Providers = 'providers',
  Locations = 'locations'
}

// property name equal to url segment
export const tabsPerPage = {
  [urlPages.PATIENT_PAGE]: {
    [PatientPageTab.OpenTickets]: 'Open Items',
    [PatientPageTab.ResolvedTickets]: 'Resolved Tickets',
    [PatientPageTab.Calls]: 'Calls',
    [PatientPageTab.EpisodesAndTasks]: 'Episodes & Tasks'
  },
  [urlPages.PRACTICE_MANAGER]: {
    [PracticeManagerTab.Users]: 'Users',
    [PracticeManagerTab.TicketTypes]: 'Ticket Types',
    [PracticeManagerTab.Episodes]: 'Episodes',
    [PracticeManagerTab.Tags]: 'Tags',
    [PracticeManagerTab.Providers]: 'Providers',
    [PracticeManagerTab.Locations]: 'Locations'
  },
  [urlPages.CARE_MANAGEMENT]: {
    all: 'Enrollment Manager',
    enrolled: 'Enrolled Patients'
  }
};

export interface UrlSortParams {
  sortBy: string;
  sortAsc: boolean;
}

export function isSortEquals(sortA: UrlSortParams | null, sortB: UrlSortParams | null) {
  const isBothNulls = sortA === null && sortB === null;
  return isBothNulls || (sortA?.sortBy === sortB?.sortBy && sortA?.sortAsc === sortB?.sortAsc);
}

export function setBooleanUrlParam(searchParams: URLSearchParams, param: string, value: boolean) {
  if (value) {
    searchParams.set(param, 'true');
  } else {
    searchParams.delete(param);
  }
}

export function getURLSearchParams() {
  const { hash } = window.location;
  const searchFragmentIndex = hash.lastIndexOf('?');
  const search = searchFragmentIndex > 0 ? hash.substring(searchFragmentIndex) : '';
  return new URLSearchParams(search);
}

export function getQueryParamFromUrl(paramKey: string) {
  const searchParams = getURLSearchParams();
  return searchParams.get(paramKey);
}

// === pagination functions === //
export function getCurrentPageByUrl(): number {
  const pageParam = getQueryParamFromUrl(PAGE_PARAM);
  return pageParam ? parseInt(pageParam) - 1 : FIRST_PAGE; // page  = url  page-value -1
}

export function getRecordsPerPageByUrl(): number {
  return Number(getQueryParamFromUrl(RECORDS_PER_PAGE_PARAM));
}

export function getCurrentSortByUrl(): UrlSortParams {
  let currentSort: UrlSortParams = null;
  const sortBy = getQueryParamFromUrl(SORT_BY_PARAM);
  const sortAsc = !!getQueryParamFromUrl(SORT_ASC_PARAM);
  if (sortBy) {
    currentSort = { sortBy, sortAsc };
  }
  return currentSort;
}
// === pagination functions END === //

export function getCurrentSortStringByUrl(): string {
  let sortStr = null;
  const sortBy = getQueryParamFromUrl(SORT_BY_PARAM);
  const sortAsc = getQueryParamFromUrl(SORT_ASC_PARAM);
  if (sortBy) {
    sortStr = sortParamsToUrlString(sortBy, !!sortAsc);
  }
  return sortStr;
}

export function sortParamsToUrlString(sortBy: string, sortAsc: boolean): string {
  const sortAscStr = sortAsc ? `&${SORT_BY_PARAM}=true` : '';
  return `${SORT_BY_PARAM}=${sortBy}${sortAscStr}`;
}

export function toHipaaCompliantUrl(url: string): string {
  return url
    .split('/')
    .map((str) => (Number.isInteger(parseInt(str)) ? 'ID' : str)) // replace numeric (id) values
    .join('/');
}

// return url as array of fragments, without query params (e.g. ['care-management', 'enrolled'])
function getPathnameSegments() {
  const { hash } = window.location;
  const queryParamsIndex = hash.indexOf('?');
  const lastPathIndex = queryParamsIndex !== -1 ? queryParamsIndex : hash.length;
  const pathname = hash.substring(2, lastPathIndex);
  return pathname.split('/').filter((segment) => segment !== '');
}

// for extracting from react router - use getPatientIdFromRoute
export function getPatientIdFromUrl(): number {
  const pathnameSegments = getPathnameSegments();

  if (pathnameSegments.length > 1 && pathnameSegments[0] === 'patient') {
    const patientId = pathnameSegments[1];
    return parseInt(patientId);
  }
}

export function getPageName(): string {
  const pathnameSegments = getPathnameSegments();
  const pageSegment = pathnameSegments[0];
  const pageName = urlPageToPageName[pageSegment];

  if (!pageName) {
    throw new Error(`unknown page ${pageSegment}`);
  }

  return pageName;
}

// note: currently patient page store tab name in query params
export function getTabName(): string {
  const pathnameSegments = getPathnameSegments();

  const page = tabsPerPage[pathnameSegments[0]];
  if (!page || pathnameSegments.length < 2) {
    return null;
  }

  const tabSegment = pathnameSegments[1];

  return page[tabSegment as keyof typeof page];
}

export function removeUrlParam(history: RouterHistory, search: string, paramName: string): void {
  const params = new URLSearchParams(search);
  params.delete(paramName);
  history.replace({ search: params.toString() });
}

export function addUrlParam(
  history: RouterHistory,
  search: string,
  paramName: string,
  value: string
): void {
  const params = new URLSearchParams(search);
  params.set(paramName, value);
  history.replace({ search: params.toString() });
}

export function useGetUrlParam(paramName: string) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get(paramName);
}
