// @ts-strict-ignore
import { compact } from 'lodash/fp';

import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import Ticket, { TicketClass } from 'models/Ticket';

import { SendMessageOption } from 'views/Widgets/SendMessageOption';

export interface TicketActionHandlers {
  resolve: () => any;
  resolveAndComment: () => any;
  reassign: () => any;
  logCall: () => any;
  rescheduleTask: () => any;
  editTask: () => any;
  editOperatorTicket: () => any;
  pickUp: () => any;
  sendPatientSms: () => any;
}

export const getTicketActionOptions = (
  actionHandlers: TicketActionHandlers,
  ticket: Ticket,
  isAssigned: boolean,
  {
    shouldShowSendMessage,
    isSmsOptionDisabled,
    shouldShowLogCallFromTask
  }: {
    shouldShowSendMessage: boolean;
    isSmsOptionDisabled: boolean;
    shouldShowLogCallFromTask: boolean;
  }
) => {
  let assignText = isAssigned ? 'Reassign' : 'Assign';
  let options = [];

  const resolve = {
    text: <span>Resolve</span>,
    id: 'resolve',
    onClick: actionHandlers.resolve,
    testHook: ticketSelectors.actions.getActionButton('resolve', ticket.id)
  };

  const resolveAndComment = {
    text: <span>Resolve & Comment</span>,
    id: 'resolveAndComment',
    onClick: actionHandlers.resolveAndComment,
    testHook: ticketSelectors.actions.getActionButton('resolve-and-comment', ticket.id)
  };

  const reassign = {
    text: <span>{assignText}</span>,
    id: 'reassign',
    onClick: actionHandlers.reassign,
    testHook: ticketSelectors.actions.getActionButton('reassign', ticket.id)
  };

  const logCall = {
    text: <span>Log Call</span>,
    id: 'logCall',
    onClick: actionHandlers.logCall,
    testHook: ticketSelectors.actions.getActionButton('log-call', ticket.id)
  };

  const editTask = {
    text: <span>Edit</span>,
    id: 'editTask',
    onClick: actionHandlers.editTask,
    testHook: `tooltip_option_editTask_${ticket.id}`
  };

  const editOperatorTicket = {
    text: <span>Edit</span>,
    id: 'editOperatorTicket',
    onClick: actionHandlers.editOperatorTicket,
    testHook: ticketSelectors.actions.getActionButton('edit', ticket.id)
  };

  const rescheduleTask = {
    text: <span>Reschedule</span>,
    id: 'reschedule',
    onClick: actionHandlers.rescheduleTask,
    testHook: `tooltip_option_reschedule_${ticket.id}`
  };

  const pickupTicket = {
    text: <span>Pick Up</span>,
    id: 'pickUpTicket',
    onClick: actionHandlers.pickUp,
    testHook: `tooltip_option_pickUpTicket_${ticket.id}`
  };

  const sendSms = shouldShowSendMessage
    ? {
        text: <SendMessageOption ticket={ticket} disabled={isSmsOptionDisabled} />,
        id: 'sendPatientSms',
        onClick: actionHandlers.sendPatientSms,
        disabled: isSmsOptionDisabled
      }
    : null;

  switch (ticket.class) {
    case TicketClass.patient:
      options = [logCall, sendSms, reassign, resolve, resolveAndComment];
      break;
    case TicketClass.operator:
      options = [logCall, sendSms, reassign, editOperatorTicket, resolve, resolveAndComment];
      break;
    case TicketClass.task:
      const editTaskOption = ticket.taskTicket.isNoneEpisode ? editTask : null;
      const logCallOption = shouldShowLogCallFromTask ? logCall : null;
      const pickUpOption = !isAssigned ? pickupTicket : null;
      options = [pickUpOption, logCallOption, reassign, rescheduleTask, editTaskOption];
      break;

    case TicketClass.callbackRequest:
      options = [logCall, sendSms, reassign, resolve, resolveAndComment];
      break;

    default:
      return [];
  }
  return compact(options);
};
