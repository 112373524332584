// @ts-strict-ignore
import { computed, observable, makeObservable } from 'mobx';

import ObjectCopier from 'utils/ObjectCopier';

import { PhoneTypeStrings } from './PhoneNumberDetails';

export interface UserPreferences {
  generateSmartSummary: boolean;
  copyNotesToClipboard: boolean;
}

export default class UserModel extends ObjectCopier {
  userId: number;

  @observable
  firstName: string;

  @observable
  lastName: string;

  @observable
  email: string;

  @observable
  gender: number;

  @observable
  picture: string;

  @observable
  phone: string;

  @observable
  phoneExtension?: string;

  @observable
  phoneType: PhoneTypeStrings;

  @observable
  smsOptOutDate: Date;

  constructor(
    userId?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    gender?: number,
    picture?: string,
    phone?: string,
    phoneType?: PhoneTypeStrings,
    smsOptOutDate?: Date,
    phoneExtension?: string
  ) {
    super();
    makeObservable(this);
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.gender = gender;
    this.picture = picture;
    this.phone = phone;
    this.phoneType = phoneType;
    this.smsOptOutDate = smsOptOutDate;
    this.phoneExtension = phoneExtension;
  }

  @computed
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get shortenedFullName(): string {
    return `${this.firstName} ${this.lastName?.charAt(0) || ''}`;
  }

  static getUserFromJson(userJson: any) {
    return new UserModel(
      userJson.userId,
      userJson.firstName,
      userJson.lastName,
      userJson.email,
      userJson.gender,
      userJson.picture,
      userJson.phone,
      userJson.phoneType
    );
  }
}
