import { FC, ReactNode } from 'react';

import { css, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import { ButtonProps, StyledText } from 'components/UIkit/atoms/Button/StyledButtons';
import { withSpacing } from 'components/UIkit/theme/spacing';

interface Props extends ButtonProps {
  icon: ReactNode;
  iconPlacement?: 'left' | 'right';
  size?: 'medium' | 'large';
  isActive?: boolean;
  variant?: 'primary' | 'secondary';
}

const TextIcon: FC<Props> = ({
  onClick,
  disabled,
  icon,
  isActive = false,
  iconPlacement = 'left',
  size = 'medium',
  testHook,
  children,
  variant = 'primary'
}) => (
  <StyledContainer
    onClick={onClick}
    disabled={disabled}
    isActive={isActive}
    iconPlacement={iconPlacement}
    data-test-hook={testHook}
    variant={variant}
  >
    {iconPlacement === 'left' && icon}
    <Typography variant={size === 'medium' ? 'button-medium-outline' : 'form-text'}>
      {children}
    </Typography>
    {iconPlacement === 'right' && icon}
  </StyledContainer>
);

const StyledContainer = styled(StyledText, {
  shouldForwardProp: (prop) => prop !== 'iconPlacement'
})<{ iconPlacement: 'left' | 'right' }>(
  ({ theme, iconPlacement }) => css`
    display: flex;
    align-items: center;
    padding: 0;
    transition: color 0.3s ease-in;
    svg {
      margin-right: ${iconPlacement === 'left' ? theme.spacing(8) : 0};
      margin-left: ${iconPlacement === 'right' ? theme.spacing(8) : 0};
    }

    &:disabled {
      svg {
        color: ${theme.palette.text.disabled};
      }
    }
  `
);

export const TextIconButton = withSpacing(TextIcon);
