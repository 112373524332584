import { FC } from 'react';

import { observer } from 'mobx-react';

import { FEATURES } from 'constants/features';

import Ticket from 'models/Ticket';

import { useFeature } from 'hooks/useFeature';

import { BaseTicketMessages } from './BaseTicketMessages';
import { TicketMessagesWithSuggestions } from './TicketMessagesWithSuggestions';

interface Props {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

export const TicketMessages: FC<Props> = observer((props) => {
  const hasSuggestionsFeature = useFeature(FEATURES.PATIENT_MESSAGING_SUGGESTIONS);

  if (hasSuggestionsFeature) {
    return <TicketMessagesWithSuggestions {...props} />;
  }

  return <BaseTicketMessages {...props} />;
});
