// @ts-strict-ignore
import i18n from 'i18n';
import { orderBy } from 'lodash';
import { isNil, orderBy as orderByFp, partition } from 'lodash/fp';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { computedFn } from 'mobx-utils';

import { rootStore } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import { CustomSentryCategories, reportBreadcrumb } from 'services/sentryService';

import { calculateAge, daysAgo, formatDate } from 'utils/DateUtils';

import { phoneTypeSupportsSMS } from 'utils/PhoneUtils';

import { API_HOST, ENV } from 'constants/config';

import { CmStatus } from 'models/CmPatient';
import { DxCode } from 'models/Conditions/SpecificCauseCondition';
import { SymptomTicketUrgency } from 'models/OperatorTicket';
import PatientProvider from 'models/PatientProvider';

import { RemoteMonitoringStatus } from 'views/Filters/FilterFields';

import Call from './Call';
import { DrugSpecific } from './DrugSpecific';
import PatientLocation from './PatientLocation';
import { PatientOptOut } from './PatientOptOut';
import { PhoneType, PhoneTypeStrings } from './PhoneNumberDetails';
import QuestionnaireAnswer, { ReportType } from './QuestionnaireAnswer';
import ScheduledProtocol, {
  ProtocolName,
  ProtocolOperationMode,
  ProtocolRecurrence,
  ProtocolType
} from './ScheduledProtocol';

import UserModel from './UserModel';

export enum RelationshipToPatientEnum {
  SELF = 'Self',
  SPOUSE = 'Spouse/Partner',
  PARENT = 'Parent',
  SIBLING = 'Sibling',
  DAUGHTER = 'Daughter',
  SON = 'Son',
  CAREGIVER = 'Caregiver',
  OTHER = 'Other'
}

export interface IPatientContact {
  id?: number;
  name?: string;
  phoneNumber?: string;
  phoneType?: PhoneType;
  phoneExtension?: string;
  relationship?: RelationshipToPatientEnum;
  deletedAt?: Date;
  smsOptedOutAt?: Date;
  email?: string;
}

export enum PatientSex {
  Male = 1,
  Female = 2,
  Other = 3
}

export enum CallbackReason {
  authFail = 'auth_fail',
  requested = 'requested'
}

export interface IPatientUpdateableFields {
  firstName: string;
  lastName: string;
  phone: string;
  phoneType: PhoneTypeStrings;
  sex: number;
  dateOfBirth: string;
  remoteMonitoringConsent: Date;
  copayConsentGiven: Date;
  patientReadTerms: Date;
  mrn: string;
  locale: string;
  protocol: ScheduledProtocol;
  optOut: PatientOptOut;
  location: PatientLocation;
  enrollmentStatus?: string;
  email: string;
  financialAssistance: boolean;
  providerId: string;
  tags: number[];
}

export enum PatientType {
  Local = 'local',
  Regular = 'regular'
}

export const callbackRelationshipOptions = Object.values(RelationshipToPatientEnum).map(
  (relationshipValue) => ({
    value: relationshipValue,
    label: relationshipValue
  })
);

const SupportedLanguages: string[] = ['es', 'en', 'hy'];

export default class Patient extends UserModel {
  @observable
  questionnairesAnswers: QuestionnaireAnswer[] = [];

  @observable
  scheduledProtocols: ScheduledProtocol[] = [];

  @observable
  mrn: string;

  @observable
  id: number;

  @observable
  type: PatientType;

  @observable
  lastReportTime: string;

  @observable
  lastReportAckTime: string;

  @observable
  lastReportNameRequested: string;

  @observable
  lastSmsRequestReportTime: Date;

  @observable
  lastAutomatedRequestReportTime: Date;

  @observable
  requestCallAttempts: number;

  @observable
  lastSnoozeTime: Date;

  @observable
  tags: number[];

  @observable
  status: string;

  @observable
  createdAt: string;

  @observable
  updatedAt: string;

  @observable
  dateOfBirth: string;

  @observable
  protocolOverdueStartTime: Date;

  @observable
  sex: number;

  @observable
  enrollmentStatus: string;

  @observable
  remoteMonitoringConsent: Date;

  @observable
  messagingConsent: Date;

  @observable
  copayConsentGiven: Date;

  @observable
  patientReadTerms: Date;

  @observable
  callbackReason: CallbackReason;

  @observable
  callsMap = observable.map<number, Call>();

  @observable
  locale: string;

  @observable
  cmStatus: CmStatus;

  @observable
  activatedAt: Date;

  @observable
  contacts: IPatientContact[];

  @observable
  isLagging: boolean;

  @observable
  isDeceased: boolean;

  providerId: string;

  @observable
  optOut: PatientOptOut;

  location: PatientLocation;

  sourceId: number;

  financialAssistance: boolean;

  emrPatientId: string;

  lastTicketCreatedAt: string;

  @observable
  noResponse: Date;

  @observable
  dxCodesMap: DataMap<DxCode> = new DataMap({ keyProp: 'code' });

  @observable
  openConversationTicketId: number;

  constructor(user: UserModel) {
    super(
      user.userId,
      user.firstName,
      user.lastName,
      user.email,
      user.gender,
      user.picture,
      user.phone,
      user.phoneType,
      user.smsOptOutDate,
      user.phoneExtension
    );
    makeObservable(this);
  }

  @computed
  get dxCodeIds() {
    return this.dxCodesMap.items.map((codeItem) => codeItem.code);
  }

  @computed
  get dxCodes() {
    return this.dxCodesMap.items;
  }

  @computed
  get calls(): Call[] {
    return Array.from(this.callsMap.values());
  }

  @computed
  get hasMessagingConsent(): boolean {
    return Boolean(this.messagingConsent);
  }

  set calls(calls) {
    runInAction(() => {
      this.callsMap.clear();
      calls.forEach((call) => this.callsMap.set(call.id, call));
    });
  }

  @computed
  get remoteMonitoringStatus(): RemoteMonitoringStatus {
    const protocol = this.closestScheduleProtocol;
    if (Boolean(this.optOutDate)) {
      return RemoteMonitoringStatus.optedOut;
    } else if (this.isActive) {
      return RemoteMonitoringStatus.active;
    } else if (protocol && protocol.operationMode === ProtocolOperationMode.Automatic) {
      if (this.noResponse) {
        return RemoteMonitoringStatus.invitedNoResponse;
      } else {
        return RemoteMonitoringStatus.invited;
      }
    } else if (this.isRemoteMonitorConsented) {
      return RemoteMonitoringStatus.awaitingActivation;
    } else {
      return RemoteMonitoringStatus.notInvited;
    }
  }

  @computed
  get callsByType(): { drafts: Call[]; savedCalls: Call[] } {
    const [drafts, savedCalls] = partition((call: Call) => call.isDraft, this.sortedCalls);

    return {
      drafts,
      savedCalls
    };
  }

  @computed
  get unacknowledgedReports(): QuestionnaireAnswer[] {
    return this.questionnairesAnswers.filter((questionnaireAnswer) =>
      questionnaireAnswer.isUnacknowledged(this.lastReportAckTime)
    );
  }

  @computed
  get hasUnacknowledgedReports(): boolean {
    return this.unacknowledgedReports.length > 0;
  }

  @computed
  get acknowledgedReports(): QuestionnaireAnswer[] {
    if (!Boolean(this.lastReportAckTime)) {
      return [];
    }
    return this.questionnairesAnswers.filter(
      (questionnaireAnswer) =>
        new Date(questionnaireAnswer.createdAt) < new Date(this.lastReportAckTime)
    );
  }

  getAcknowledgedReportsByTicketIdSortedBySeverity(ticketId: number) {
    if (!Boolean(this.lastReportAckTime)) {
      return [];
    }

    return this.questionnairesAnswers
      .filter(
        (questionnaireAnswer) =>
          questionnaireAnswer.ticketId === ticketId &&
          new Date(questionnaireAnswer.createdAt) < new Date(this.lastReportAckTime)
      )
      .sort(this.sortReportsBySeverity);
  }

  @computed
  get hasDrugSpecificHourlyProtocol(): boolean {
    return (
      this.hasDrugSpecificProtocol &&
      this.mainScheduledProtocol.getFrequencyUnit() === ProtocolRecurrence.HOURLY
    );
  }

  @computed
  get drugSpecific(): DrugSpecific | undefined {
    const protocol = this.mainScheduledProtocol;
    return rootStore.stores.constantsStore.getSpecificDrugById(protocol?.info?.drugId);
  }

  @computed
  get sortedAcknowledgedReports(): QuestionnaireAnswer[] {
    return orderByFp(['createdAt'], ['asc'], this.acknowledgedReports);
  }

  @computed get reportsSortedBySeverity() {
    return this.unacknowledgedReports.sort(this.sortReportsBySeverity);
  }

  /**
    sort by descending severity - highest severity shown first
    if same severity is found it will order latest first (creation date)
  */
  sortReportsBySeverity = (reportA: QuestionnaireAnswer, reportB: QuestionnaireAnswer) => {
    if (reportA.urgency === reportB.urgency) {
      if (reportA.createdAt !== reportB.createdAt) {
        return reportA.isAfter(new Date(reportB.createdAt)) ? -1 : 1;
      }
      return 0;
    }

    const urgencyA = isNil(reportA.urgency) ? -1 : reportA.urgency;
    const urgencyB = isNil(reportB.urgency) ? -1 : reportB.urgency;

    return urgencyB - urgencyA;
  };

  @computed
  get isLastReportUnAcknowledged(): boolean {
    return (
      !!this.lastReportTime &&
      (!this.lastReportAckTime || new Date(this.lastReportAckTime) < new Date(this.lastReportTime))
    );
  }

  @computed
  get hasUrgentReports(): boolean {
    return this.unacknowledgedReports.some(
      (report) => report.urgency >= SymptomTicketUrgency.NurseReview
    );
  }

  @computed
  get closestScheduleProtocol() {
    if (this.scheduledProtocols.length === 0) {
      return null;
    }
    return this.scheduledProtocols
      .slice()
      .sort(
        (protocolA: ScheduledProtocol, protocolB: ScheduledProtocol) =>
          protocolA.lastReportIntervalAt.getTime() - protocolB.lastReportIntervalAt.getTime()
      )[0];
  }

  @computed
  get isFemale(): boolean {
    return this.sex === PatientSex.Female; // Beware! we have sex = other, so not female does not automatically mean male!
  }

  @computed
  get sexText(): string {
    return Patient.getSexText(this.sex);
  }

  @computed
  get distressOralAndDrugReports(): QuestionnaireAnswer[] {
    return this.questionnairesAnswers.filter(
      (report) =>
        report.type === ReportType.Oral ||
        report.type === ReportType.Distress ||
        report.type === ReportType.DrugSpecific
    );
  }

  @computed
  get lastReportIntervalAt() {
    if (
      !this.closestScheduleProtocol ||
      !this.closestScheduleProtocol.lastReportIntervalAt ||
      this.closestScheduleProtocol.lastReportIntervalAt.getTime() === 0
    ) {
      // TODO: remove when default value changes to null
      return null;
    }
    return this.closestScheduleProtocol.lastReportIntervalAt;
  }

  @computed
  get lastReportFormattedDate(): string {
    return this.lastReportTime ? formatDate(this.lastReportTime) : null;
  }

  get optOutDate(): Date | null {
    return this.optOut ? this.optOut.createdAt : null;
  }

  get formattedOptedOutDate(): string {
    return this.optOutDate ? this.optOut.formattedCreatedDate : null;
  }

  @computed
  get lastReport() {
    return this.getLastNthReport(0);
  }

  get locationId() {
    return this.location ? this.location.id : null;
  }

  @computed
  get sortedReports() {
    return orderBy(
      this.questionnairesAnswers,
      (questionnaireAnswer: QuestionnaireAnswer) =>
        new Date(questionnaireAnswer.createdAt).valueOf(),
      ['desc']
    );
  }

  getLastNthReport = (n: number) => {
    if (this.questionnairesAnswers.length <= n) {
      return null;
    }
    return this.sortedReports[n];
  };

  @computed
  get isStatusInactive(): boolean {
    return !(this.status === 'Deceased' || this.status === 'Remission');
  }

  @computed
  get isActive(): boolean {
    return this.activatedAt && !this.optOut;
  }

  @computed
  get mainScheduledProtocol(): ScheduledProtocol {
    return !!this.scheduledProtocols && this.scheduledProtocols.length > 0
      ? this.scheduledProtocols[0]
      : null;
  }

  @computed
  get hasDrugSpecificProtocol(): boolean {
    return (
      Boolean(this.mainScheduledProtocol) &&
      this.mainScheduledProtocol.name === ProtocolName.drugSpecific
    );
  }

  @computed
  get hasOralAutomaticProtocol() {
    return (
      Boolean(this.mainScheduledProtocol) && this.mainScheduledProtocol.isOralAutomaticProtocol()
    );
  }

  @computed
  get hasOralProtocol() {
    return Boolean(this.mainScheduledProtocol?.name === ProtocolName.oralOncolytics);
  }

  @computed
  get hasMobilePhone(): boolean {
    return !!this.phone ? phoneTypeSupportsSMS(this.phoneType) : false;
  }

  @computed
  get hasPersonalRequestSent() {
    const smsRequestSent =
      !!this.lastSmsRequestReportTime && this.lastSmsRequestReportTime > this.lastReportIntervalAt;
    const automatedRequestSent =
      !!this.lastAutomatedRequestReportTime &&
      this.lastAutomatedRequestReportTime > this.lastReportIntervalAt;
    return smsRequestSent || automatedRequestSent;
  }

  @computed
  get hasPatientSnoozed() {
    return this.lastSnoozeTime && this.lastSnoozeTime > this.lastReportIntervalAt;
  }

  @computed
  get hasPatientCalledSinceNeededToReport() {
    return this.lastCall && new Date(this.lastCall.createdAt) > new Date(this.lastReportIntervalAt);
  }

  @computed
  get lastCall() {
    if (this.calls.length === 0) {
      return null;
    }
    return this.sortedCalls[0];
  }

  @computed
  get sortedCalls() {
    return orderBy(this.calls, [(call: Call) => call.createdAt.valueOf()], ['desc']);
  }

  @computed
  get callsLast30Days() {
    return this.calls.filter((call) => call.createdAt > daysAgo(30));
  }

  @computed
  get age() {
    return calculateAge(new Date(this.dateOfBirth));
  }

  @computed
  get formattedDateOfBirth(): string | null {
    return this.dateOfBirth ? formatDate(this.dateOfBirth, 'L') : null;
  }

  @computed
  get formattedRemoteMonitoringConsent(): string | null {
    return this.remoteMonitoringConsent ? formatDate(this.remoteMonitoringConsent, 'L') : null;
  }

  @computed
  get provider(): PatientProvider {
    return rootStore.stores.providersStore.getProviderById(this.providerId);
  }

  @action
  setUpdate(update: IPatientUpdateableFields) {
    if (
      (this.scheduledProtocols && !update.protocol) ||
      (this.scheduledProtocols[0] && this.scheduledProtocols[0].name !== update.protocol.name)
    ) {
      this.lastSmsRequestReportTime = null;
      this.lastAutomatedRequestReportTime = null;
    }
    this.firstName = update.firstName;
    this.lastName = update.lastName;
    this.phone = update.phone;
    this.phoneType = update.phoneType;
    this.providerId = update.providerId;
    this.location = update.location;
    this.enrollmentStatus = update.enrollmentStatus;
    this.dateOfBirth = update.dateOfBirth;
    this.remoteMonitoringConsent = update.remoteMonitoringConsent;
    this.copayConsentGiven = update.copayConsentGiven;
    this.patientReadTerms = update.patientReadTerms;
    this.sex = update.sex;
    this.mrn = update.mrn;
    this.scheduledProtocols = update.protocol ? [update.protocol] : [];
    this.optOut = update.optOut;
    this.tags = update.tags;
    this.locale = update.locale;
    this.remoteMonitoringConsent = update.remoteMonitoringConsent;
    this.copayConsentGiven = update.copayConsentGiven;
    this.patientReadTerms = update.patientReadTerms;
    this.financialAssistance = update.financialAssistance;
    this.email = update.email;
  }

  @computed
  get hasBeenRemindedBySms() {
    return (
      this.lastSmsRequestReportTime &&
      (!this.lastReportIntervalAt || this.lastSmsRequestReportTime > this.lastReportIntervalAt) &&
      (!this.lastReportTime || this.lastSmsRequestReportTime > new Date(this.lastReportTime))
    );
  }

  @computed
  get hasBeenSentAutomatedReportRequest() {
    return (
      this.lastAutomatedRequestReportTime &&
      (!this.lastReportIntervalAt ||
        this.lastAutomatedRequestReportTime > this.lastReportIntervalAt) &&
      (!this.lastReportTime || this.lastAutomatedRequestReportTime > new Date(this.lastReportTime))
    );
  }

  @computed
  get patientCalledInLastDay() {
    return this.lastCall && daysAgo(1) < this.lastCall.createdAt;
  }

  @computed
  get requestReportInLastDay() {
    const smsRequestInLastDay =
      this.lastSmsRequestReportTime && daysAgo(1) < this.lastSmsRequestReportTime;
    const automatedRequestInLastDay =
      this.lastAutomatedRequestReportTime && daysAgo(1) < this.lastAutomatedRequestReportTime;
    return smsRequestInLastDay || automatedRequestInLastDay;
  }

  @computed
  get isRemindedWithSMS() {
    if (!this.mainScheduledProtocol) {
      return false;
    }
    return (
      this.mainScheduledProtocol.type === ProtocolType.mobile ||
      this.mainScheduledProtocol.type === ProtocolType.chatbot
    );
  }

  @computed
  get shouldSendReportReminder() {
    if (this.isRemindedWithSMS && this.isSMSDisabled) {
      return false;
    }
    return !this.hasPersonalRequestSent;
  }

  getDefaultReportReminderText = computedFn((clinicianName: string, institutionName: string) => {
    if (!this.mainScheduledProtocol) {
      throw new Error('attempt to remind patient without protocol');
    }
    if (
      this.mainScheduledProtocol.name === ProtocolName.symptom ||
      this.mainScheduledProtocol.name === ProtocolName.oralOncolytics
    ) {
      return i18n.t('defaultSymptomsReminderSms', {
        name: this.firstName,
        doctor: clinicianName,
        institution: institutionName,
        phone: ENV === 'production' ? '(833) 288-7683' : '(213) 270-1782',
        url: `${API_HOST}/app`,
        lng: this.locale
      });
    }
    if (this.mainScheduledProtocol.name === ProtocolName.covid) {
      return i18n.t('defaultCovidReminderSms', {
        name: this.firstName,
        doctor: clinicianName,
        phone: ENV === 'production' ? '(833) 288-7683' : '(213) 270-1782',
        url: `${API_HOST}/app`,
        lng: this.locale
      });
    }
  });

  @computed
  get lastReportRequestDate() {
    if (!this.lastSmsRequestReportTime && !this.lastAutomatedRequestReportTime) {
      return null;
    }
    if (!this.lastAutomatedRequestReportTime && this.lastSmsRequestReportTime) {
      return this.lastSmsRequestReportTime;
    }
    if (this.lastAutomatedRequestReportTime && !this.lastSmsRequestReportTime) {
      return this.lastAutomatedRequestReportTime;
    }
    return this.lastAutomatedRequestReportTime < this.lastSmsRequestReportTime
      ? this.lastSmsRequestReportTime
      : this.lastAutomatedRequestReportTime;
  }

  @computed
  get activeContacts(): IPatientContact[] {
    return this.contacts.filter((contact: IPatientContact) => !contact.deletedAt);
  }

  @computed
  get isSMSDisabled() {
    return !!this.smsOptOutDate;
  }

  @computed
  get isRemoteMonitorConsented(): boolean {
    return Boolean(this.remoteMonitoringConsent);
  }

  @computed
  get isSymptomReportOverdue() {
    if (this.hasOralProtocol || this.hasPatientSnoozed) {
      return false;
    }
    return this.isOverdue;
  }

  @computed
  private get isOverdue() {
    if (!this.isLagging) {
      return false;
    }
    if (this.requestReportInLastDay || this.patientCalledInLastDay) {
      return false;
    }
    return true;
  }

  @computed
  get isOralReportOverdue() {
    if (!this.hasOralProtocol) {
      return false;
    }
    return this.isOverdue;
  }

  @computed
  get oralChemoProtocol(): ScheduledProtocol | undefined {
    return this.scheduledProtocols.find((protocol) => protocol.info?.regimen);
  }

  @computed
  get regimenTitle() {
    const regimen = this.oralChemoProtocol?.info?.regimen;
    if (!regimen) {
      return '';
    }

    return `${regimen.name}${regimen.isCustom ? ' - Custom' : ''}`;
  }

  getContactById = (contactId?: number): IPatientContact | null => {
    return this.contacts.find((contact) => contact.id === contactId) || null;
  };

  @action
  resetCallbackInfo() {
    this.callbackReason = null;
  }

  @action
  addContact(contact: IPatientContact) {
    this.contacts = [...this.contacts, contact];
  }

  @action
  updateContact(id: number, newContact: IPatientContact) {
    this.contacts = this.contacts.map((contact) => {
      if (contact.id === id) {
        return { ...contact, ...newContact };
      }
      return contact;
    });
  }

  @action
  deleteContact(contactId: number) {
    this.updateContact(contactId, { deletedAt: new Date() });
  }

  static getSexText(sex: PatientSex): string {
    switch (sex) {
      case PatientSex.Male:
        return 'Male';
      case PatientSex.Female:
        return 'Female';
      case PatientSex.Other:
        return 'Other';
      default:
        reportBreadcrumb({
          level: 'info',
          message: 'getSexText',
          category: CustomSentryCategories.DEBUG,
          data: {
            sex
          }
        });
        return '';
    }
  }
  // This exists because currently (sep 2022) the mobile clients send different locales (language only/language+region), need to get rid of this once it's standardized
  @computed
  get language() {
    const language = this.locale?.substring(0, 2);
    if (SupportedLanguages.includes(language)) {
      return language;
    }
    return 'en';
  }
}
