// @ts-strict-ignore

import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Dialogs } from 'analytics/events/dialog';
import classNames from 'classnames';
import { ErrorName } from 'errors';
import { observer, Provider } from 'mobx-react';
import ReactDOMServer from 'react-dom/server';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';

import { useMount } from 'react-use';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';
import { rootStore, UiStore } from 'mobx/stores';

import networkLabelService from 'services/networkLabelService';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { API_URLS } from 'constants/apiUrls';

import Call, {
  CallDurationInterval,
  CallDurationValidationErrorEnum,
  CommunicationType,
  getCallDurationErrorOrNull,
  MAX_TOTAL_CALL_DURATION_IN_HOURS
} from 'models/Call';
import CallLogIntervalAuditTrailRecord, {
  CallLogAuditTrailType
} from 'models/CallLogIntervalAuditTrailRecord';
import CallReason, { CallTopicDiscussed } from 'models/CallReason';
import { ICallSaveOptions } from 'models/CallSaveOptions';
import { ContactOption } from 'models/ContactOptions';
import { DurationInterval } from 'models/DurationInterval';
import Patient from 'models/Patient';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';
import { usePrepareCall } from 'hooks/usePrepareCall';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { useWillMount } from 'hooks/useWillMount';

import { CallReasonSelectionModal } from 'views/Modals/CallReasonSelectionModal';
import ConnectTicketsToCallModal from 'views/Modals/ConnectTicketsToCallModal';

import PathwaysView from 'views/Patient/PatientMain/PathwaysView';
import { PATIENT_TABS } from 'views/Patient/PatientMain/PatientMainView.constants';
import 'views/Widgets/CallLogging/CallLogging.scss';
import { CallLoggingBody } from 'views/Widgets/CallLogging/CallLoggingBody/CallLoggingBody';
import { HomeCareInstructionsFieldValues } from 'views/Widgets/CallLogging/CallLoggingBody/HomeCareInstructionsSection';
import { disabledSummaryText } from 'views/Widgets/CallLogging/CallLoggingSummary';

import GeneratedCallDetailsView from 'views/Widgets/GeneratedCallDetailsView';

import { IntervalContextProvider } from 'components/IntervalsEditor/IntervalsContext';
import IntervalsEditorModal, {
  IntervalsEditorResult
} from 'components/IntervalsEditor/IntervalsEditorModal';
import Loading from 'components/Loaders/Loading';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { Text } from 'components/UIkit/atoms/Text';

import { CallLoggingFooter, CallLoggingFooterFormFields } from './CallLoggingFooter';
import { CallLoggingHeader } from './CallLoggingHeader';

interface ICallLoggingProps {
  patient: Patient;
  call?: Call | null;
  uiStore?: UiStore;
  onCallCanceled: () => void;
  onNewCallSaved: (call: Call, options: ICallSaveOptions, markup?: string) => Promise<any>;
  selectedTab?: string;
  hasServerError: boolean;
}

const CallContainer: FC<ICallLoggingProps> = (props) => {
  let auditTrail: CallLogIntervalAuditTrailRecord[] = [];

  const {
    userStore,
    pathwaysStore,
    callsStore,
    callLoggingStore,
    settingsStore,
    careTimerStore,
    ticketsStore
  } = useStores();

  const [shouldSendCallToEmr, setShouldSendCallToEmr] = useState(
    settingsStore.institutionSettings.saveCallSendToEmrByDefault
  );

  const { userPreferences, setUserPreferences } = useUserPreferences();
  const prepareCall = usePrepareCall(props.patient, userPreferences, auditTrail);

  useWillMount(() => {
    callLoggingStore.resetCurrentCall(props.call);

    if (!pathwaysStore.pathwaysData) {
      pathwaysStore.fetchPathwayTemplates();
    }
  });

  const [isDeleteCallPopupOpen, setIsDeletePopupOpen] = useState(false);
  const [showInvalidDurationsWarningDialog, setShowInvalidDurationsWarningDialog] = useState(false);
  const [showCallDurationOverflowDialog, setShowCallDurationOverflowDialog] = useState(false);
  const [
    isHomeCareInstructionsDraftNotSentDialogOpen,
    setIsHomeCareInstructionsDraftNotSentDialogOpen
  ] = useState(false);
  const [showPauseOtherTimersModal, setShowPauseOtherTimersModal] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [isSaveInProgress, setSaveInProgress] = useState(false);
  const [isCallReasonSelectionModalOpen, setCallReasonSelectionModalOpen] = useState(false);
  const [isShowingPathways, setShowPathways] = useState(false);
  const [showConnectTicketsModal, setShowConnectTicketsModal] = useState(false);
  const [wasDeletedDraftWarningShown, setWasDeletedDraftWarningShown] = useState(false);
  const { currentCall } = callLoggingStore;

  const defaultIsOriginalGeneratedSmartSummary =
    Boolean(currentCall.summary) && !currentCall.isSummaryManuallyEdited;

  const [originalGeneratedSmartSummary, setOriginalGeneratedSmartSummary] = useState(
    defaultIsOriginalGeneratedSmartSummary ? currentCall.summary : ''
  );

  const { openDisconnectDraftPopup } = useTicketOverviewContext();

  const smartSummaryFormMethods = useForm<CallLoggingFooterFormFields>({
    defaultValues: {
      summary: currentCall.summary,
      includeSummaryInEmrNote: currentCall.includeSummaryInEmrNote
    }
  });

  const getRecipientsDefaultValue = () => {
    const patientOptions: ContactOption[] = [];
    const { patient } = props;
    if (currentCall.homeCareInstructionsDraft?.recipients?.length) {
      for (const recipient of currentCall.homeCareInstructionsDraft.recipients) {
        if (recipient.contactId) {
          patientOptions.push({
            contactId: recipient.contactId,
            value: `${recipient.contactId}_${recipient.communicationType}`,
            label: recipient.label,
            communicationType: recipient.communicationType,
            actualValue: recipient.actualValue
          });
        } else {
          patientOptions.push({
            value: `${patient.id}_${recipient.communicationType}`,
            actualValue: patient.phone,
            label: getFormattedPhoneNumber(patient.phone),
            communicationType: recipient.communicationType
          });
        }
      }
    } else if (patient.phone) {
      patientOptions.push({
        value: `${patient.id}_${CommunicationType.Sms}`,
        label: getFormattedPhoneNumber(patient.phone),
        communicationType: CommunicationType.Sms,
        actualValue: patient.phone
      });
    }
    return patientOptions;
  };

  const homeCareInstructionsFormMethods = useForm<HomeCareInstructionsFieldValues>({
    defaultValues: {
      text: callLoggingStore.currentCall.homeCareInstructionsDraft?.text || '',
      messagingConsent: props.patient.hasMessagingConsent,
      recipients: getRecipientsDefaultValue()
    }
  });

  //We need the patient tickets when the user trys to save the call as a draft
  useMount(function fetchTicketsForPatientWhenInCallsTab() {
    if (props.selectedTab === PATIENT_TABS.CALLS) {
      ticketsStore.fetchTicketsForPatient(props.patient.id);
    }
  });

  const userFullNameWithClinician = useClinicianFullNameWithCredentials(
    userStore.currentDoctor.firstName,
    userStore.currentDoctor.lastName,
    userStore.currentDoctor.credentialId
  );
  useEffect(
    function setInitialSaveOptions() {
      setShouldSendCallToEmr(settingsStore.institutionSettings.saveCallSendToEmrByDefault);
    },
    [callLoggingStore, settingsStore.institutionSettings]
  );

  useEffect(
    function setupNewCall() {
      pathwaysStore.clearAnswers();
      if (props.call) {
        pathwaysStore.setStoreForCall(props.call);
      }
      callLoggingStore.resetCurrentCall(props.call);
      if (callLoggingStore.connectedTaskId) {
        const task = ticketsStore.ticketsMap.get(callLoggingStore.connectedTaskId);
        if (task && task.taskTicket) {
          const taskTicket = task.taskTicket;
          callLoggingStore.updateCurrentCall({
            note: `Call Regarding Task: ${taskTicket.name}\n${
              taskTicket.description ? `\nDescription: ${taskTicket.description}` : ''
            }\nTask Due: ${taskTicket.formattedDueDate}${
              taskTicket.owner
                ? `\nTask Owner: ${taskTicket.owner.firstName} ${taskTicket.owner.lastName}`
                : ''
            }\n\n`
          });
        }
      }
      if (!callLoggingStore.isGlobalCallLoggingTimerActive) {
        callLoggingStore.startCallTimer({
          id: userStore.currentDoctor.id,
          name: userFullNameWithClinician
        });
      }

      return () => {
        if (callLoggingStore.isTimerActive()) {
          callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
        }

        callLoggingStore.callTimer && callLoggingStore.clearCallTimer();
        callLoggingStore.callTimer = null;
        careTimerStore.setIdleDisabled(false);
      };
    },
    [
      careTimerStore,
      callLoggingStore,
      pathwaysStore,
      props.call,
      userStore.currentDoctor.id,
      userFullNameWithClinician,
      ticketsStore.ticketsMap
    ]
  );

  const handleWindowClosed = useCallback(() => {
    if (callLoggingStore.isTimerActive() && callLoggingStore.isGlobalCallLoggingTimerActive) {
      callLoggingStore.endCall();
    }
  }, [callLoggingStore]);

  useEffect(
    function endCallOnUnload() {
      window.addEventListener('unload', handleWindowClosed);
      return () => {
        window.removeEventListener('unload', handleWindowClosed);
      };
    },
    [handleWindowClosed]
  );

  useEffect(
    function checkCallDuration() {
      if (
        currentCall.callDuration / 3600 <= MAX_TOTAL_CALL_DURATION_IN_HOURS &&
        (currentCall.callDuration + 1) / 3600 > MAX_TOTAL_CALL_DURATION_IN_HOURS
      ) {
        toggleMaximumCallDurationReachedDialog();
      }
      if (shouldEndTimers()) {
        callLoggingStore.endCall(false);
      }
    },
    // eslint-disable-next-line
    [currentCall.callDuration]
  );

  const shouldEndTimers = () => {
    return (
      callLoggingStore.isTimerActive() &&
      currentCall.callDuration &&
      !callLoggingStore.isGlobalCallLoggingTimerActive
    );
  };

  const handleToggleMinimize = () => {
    trackActionButtonAnalyticsEvent({
      action: callLoggingStore.isCallLoggerMinimized
        ? AnalyticEventAction.Maximize
        : AnalyticEventAction.Minimize,
      virtual_page: 'call logger'
    });

    callLoggingStore.toggleMinimize();
  };

  const handleCloseBtnClicked = () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.CancelIcon,
      virtual_page: 'call logger'
    });
    toggleDeletePopup();
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeleteCallPopupOpen);
  };

  const toggleInaccurateDurationsWarningDialog = () => {
    setShowInvalidDurationsWarningDialog(!showInvalidDurationsWarningDialog);
  };

  const toggleMaximumCallDurationReachedDialog = () => {
    setShowCallDurationOverflowDialog(!showCallDurationOverflowDialog);
  };

  const toggleHomeCareInstructionsDraftNotSent = () => {
    setIsHomeCareInstructionsDraftNotSentDialogOpen(!isHomeCareInstructionsDraftNotSentDialogOpen);
  };

  const toggleCallReasonSelectionModal = () => {
    setCallReasonSelectionModalOpen(!isCallReasonSelectionModalOpen);
  };

  const handleCallReasonsSaved = (
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[]
  ) => {
    setCallReasonSelectionModalOpen(false);
    callLoggingStore.updateCurrentCall({ callReasons, topicsDiscussed });
    submitCall();
  };

  const onSubmitCallClicked = () => {
    const { callDuration } = currentCall;
    const callError: CallDurationValidationErrorEnum = getCallDurationErrorOrNull(callDuration);

    if (callLoggingStore.callTimer) {
      callLoggingStore.endCall();
    }

    switch (callError) {
      case CallDurationValidationErrorEnum.INACCURATE_DURATION:
        toggleInaccurateDurationsWarningDialog();
        break;
      case CallDurationValidationErrorEnum.MAXIMUM_DURATION_REACHED:
        toggleMaximumCallDurationReachedDialog();
        break;
    }

    if (Boolean(homeCareInstructionsFormMethods.getValues('text').trim())) {
      toggleHomeCareInstructionsDraftNotSent();
      return;
    }

    tryToSubmitCall();
  };

  const tryToSubmitCall = () => {
    const { patientHasOpenTickets } = ticketsStore;
    if (patientHasOpenTickets(props.patient)) {
      toggleConnectTicketsModal();
    } else {
      toggleCallReasonSelectionModal();
    }
  };

  const toggleConnectTicketsModal = () => {
    setShowConnectTicketsModal(!showConnectTicketsModal);
  };

  const onSaveDraftClick = (isCallAttempt?: boolean) => {
    handleWindowClosed();
    submitCall(true, isCallAttempt);
    return;
  };

  const getIsSummaryManuallyEdited = () => {
    const currentSummary = smartSummaryFormMethods.getValues('summary');

    if (currentSummary === disabledSummaryText) {
      return false;
    }

    let isSummaryManuallyEdited = originalGeneratedSmartSummary !== currentSummary;

    //2 scenarios:
    // - if the user opens a draft call with an unchanged smart summary.
    // - if the user doesn't generate a smart summary
    if (!originalGeneratedSmartSummary) {
      isSummaryManuallyEdited = currentCall.isSummaryManuallyEdited;
    }

    return isSummaryManuallyEdited;
  };

  const submitCall = (
    isDraft: boolean = false,
    isCallAttempt: boolean = false,
    shouldResolveAll: boolean = false
  ) => {
    const [summary, includeSummaryInEmrNote] = smartSummaryFormMethods.getValues([
      'summary',
      'includeSummaryInEmrNote'
    ]);
    const [text, recipients] = homeCareInstructionsFormMethods.getValues(['text', 'recipients']);
    const homeCareInstructionsDraft = {
      text,
      recipients
    };

    callLoggingStore.updateCurrentCall({
      summary,
      includeSummaryInEmrNote,
      isSummaryManuallyEdited,
      homeCareInstructionsDraft
    });

    setSaveInProgress(true); // prevent saving another call while saving in progress

    const { patient } = props;
    const call = prepareCall(isDraft, isCallAttempt);

    let staticMarkup: string;
    const isMarkupRequired = userPreferences.copyNotesToClipboard && !isDraft;

    if (isMarkupRequired) {
      let connectedTickets;
      if (call.ticketIds?.size > 0) {
        connectedTickets = Array.from(call.ticketIds).map((ticketId) =>
          ticketsStore.ticketsMap.get(ticketId)
        );
      }
      // TODO: use server to create markup (see: EH-5600)
      staticMarkup = ReactDOMServer.renderToStaticMarkup(
        <Provider {...rootStore.stores}>
          <GeneratedCallDetailsView
            call={call}
            patient={patient}
            pathwayAnswers={call.orderedPathwayAnswers}
            primarySymptoms={currentCall.callReasons}
            connectedTickets={connectedTickets}
          />
        </Provider>
      );
    }

    const callSaveOptions: ICallSaveOptions = {
      sendToEmr: shouldSendCallToEmr,
      copyToClipboard: userPreferences.copyNotesToClipboard,
      resolveConnectedTickets: shouldResolveAll
    };

    props
      .onNewCallSaved(
        call,
        isDraft ? { copyToClipboard: false, sendToEmr: false } : callSaveOptions,
        staticMarkup
      )
      .catch((error) => {
        if (error.name === ErrorName.TicketConnectedToDraft) {
          // call is transitioning from draft to save call we do not want to disconnect it before the transition
          const excludedCallIds = !call.isDraft && call.id ? [call.id] : undefined;
          openDisconnectDraftPopup({
            ticketIds: call.ticketIdsArray,
            excludedCallIds,
            callback: () => {
              props.onNewCallSaved(
                call,
                isDraft ? { copyToClipboard: false, sendToEmr: false } : callSaveOptions,
                staticMarkup
              );
            }
          });
        } else throw error;
      })
      // reset isSaveInProgress
      .finally(() => setSaveInProgress(false));
  };

  const renderDeleteCallPrompt = () => {
    return (
      <MessageDialog
        testHook={patientPageTestSelectors.callLogger.deleteCallDialog.container}
        id={Dialogs.DeleteCall}
        isOpen={isDeleteCallPopupOpen}
        title="Delete Call"
        handleClose={() => setIsDeletePopupOpen(false)}
        primaryActionProps={{
          text: 'Delete',
          onClick: () => {
            props.onCallCanceled();
            setIsDeletePopupOpen(false);
          }
        }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: () => setIsDeletePopupOpen(false)
        }}
      >
        Are you sure you want to delete this call?
      </MessageDialog>
    );
  };

  const dismissDraftDeletedWarning = () => {
    setWasDeletedDraftWarningShown(true);
    callLoggingStore.updateCurrentCall({ ...currentCall, id: null });
  };
  const renderCallDeletedPrompt = () => {
    const shouldShowDraftDeletedWarning =
      currentCall.isDraft &&
      currentCall.id &&
      !props.patient.calls.find((call) => call.id === currentCall.id) &&
      !wasDeletedDraftWarningShown;
    return (
      <MessageDialog
        id={Dialogs.DraftCallWasDeleted}
        isOpen={shouldShowDraftDeletedWarning}
        title="This call has been deleted, but you can still save it as a new call"
        handleClose={dismissDraftDeletedWarning}
        primaryActionProps={{ text: 'OK', onClick: dismissDraftDeletedWarning }}
      >
        This draft call was deleted, but if you save it again, it will be saved as a new call.
      </MessageDialog>
    );
  };

  const renderInaccurateDurationsDialog = () => {
    return (
      <MessageDialog
        id={Dialogs.ConfirmDuration}
        isOpen={showInvalidDurationsWarningDialog}
        title="Please Confirm Duration"
        handleClose={() => setShowInvalidDurationsWarningDialog(false)}
        primaryActionProps={{
          text: 'Save Call',
          onClick: () => {
            toggleInaccurateDurationsWarningDialog();
            tryToSubmitCall();
          }
        }}
        secondaryActionProps={{
          text: 'Edit Timers',
          onClick: toggleInaccurateDurationsWarningDialog
        }}
      >
        This call is {callLoggingStore.durationStrFormat(Number(currentCall.callDuration))} long. If
        this is not accurate, place your mouse on the Call Timer and click ‘Edit Timer’.
      </MessageDialog>
    );
  };

  const renderMaxCallTimeReachedDialog = () => {
    return (
      <MessageDialog
        id={Dialogs.MaximumDurationReached}
        isOpen={showCallDurationOverflowDialog}
        title="Maximum Duration Reached"
        handleClose={() => setShowCallDurationOverflowDialog(false)}
        primaryActionProps={{ text: 'OK', onClick: () => setShowCallDurationOverflowDialog(false) }}
      >
        This call is over ${MAX_TOTAL_CALL_DURATION_IN_HOURS} hours. Correct the duration by placing
        your mouse on the Call Timer and clicking ‘Edit Timer’.
      </MessageDialog>
    );
  };

  const openEditDurationModal = () => {
    setCurrentlyEditing(true);
  };

  const validateInterval = async (interval: DurationInterval): Promise<string | null> => {
    const { patient } = props;
    if (interval.startDate && interval.endDate) {
      return await callsStore.validateInterval(interval.startDate, interval.endDate, patient.id);
    }
    return null;
  };

  const renderEditDurationModel = () => {
    return (
      <IntervalContextProvider
        initialIntervals={callLoggingStore.currentCall.durationIntervals}
        externalValidationAction={validateInterval}
      >
        <IntervalsEditorModal
          isOpen={currentlyEditing}
          title="Edit Call Timer"
          virtualPage="call logger"
          onSave={onDurationEditSaved}
          onClose={closeEditDurationModal}
        />
      </IntervalContextProvider>
    );
  };
  const onDurationEditSaved = ({ intervals, deletedIntervals }: IntervalsEditorResult) => {
    const deletedIntervalIds = new Set<number>(deletedIntervals.map((item) => item.id));
    const durationIntervals = intervals.map((interval) =>
      CallDurationInterval.fromDurationInterval(interval)
    );
    updateAuditTrail(durationIntervals, deletedIntervalIds);
    setCurrentlyEditing(null);
    callLoggingStore.updateCurrentCall({
      durationIntervals,
      callDuration: sumTotalDurationOfIntervals(durationIntervals)
    });
  };

  const closeEditDurationModal = () => {
    setCurrentlyEditing(null);
  };

  const renderHelmet = () => {
    const { patient } = props;
    return (
      <Helmet>
        <title>{` ${callLoggingStore.isTimerActive() ? 'Active Call: ' : ''} ${
          patient.fullName
        } - Canopy`}</title>
      </Helmet>
    );
  };

  const sumTotalDurationOfIntervals = (durationIntervals: CallDurationInterval[]) => {
    return durationIntervals.reduce((totalDuration: number, interval: DurationInterval) => {
      return totalDuration + interval.durationInSeconds;
    }, 0);
  };

  const updateAuditTrail = (
    updatedDurationIntervals: DurationInterval[],
    deletedIntervalIds: Set<number>
  ) => {
    const { durationIntervals } = currentCall;
    const durationIntervalsMap = durationIntervals.reduce(
      (map, interval) => map.set(interval.uniqueIdentifier, interval),
      new Map()
    );
    const durationIntervalsIdSet = new Set(durationIntervalsMap.keys());

    durationIntervals.forEach((interval) => {
      if (deletedIntervalIds.has(interval.id)) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.DELETE,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate
          )
        );
      }
    });

    updatedDurationIntervals.forEach((interval) => {
      if (
        durationIntervalsIdSet.has(interval.uniqueIdentifier) &&
        !interval.equals(durationIntervalsMap.get(interval.uniqueIdentifier))
      ) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.EDIT,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate,
            {
              startDate: durationIntervalsMap.get(interval.uniqueIdentifier).startDate,
              endDate: durationIntervalsMap.get(interval.uniqueIdentifier).endDate
            }
          )
        );
      } else if (!durationIntervalsIdSet.has(interval.uniqueIdentifier)) {
        auditTrail.push(
          new CallLogIntervalAuditTrailRecord(
            CallLogAuditTrailType.ADD,
            interval.uniqueIdentifier,
            interval.startDate,
            interval.endDate
          )
        );
      }
    });
  };

  const renderOtherActiveCallDialog = () => {
    return (
      <MessageDialog
        id={Dialogs.AnotherCallTimerIsRunning}
        isOpen={showPauseOtherTimersModal}
        title="Another Call Timer is Running"
        handleClose={() => setShowPauseOtherTimersModal(false)}
        primaryActionProps={{ text: 'Pause Other Timers', onClick: onClickedPauseOtherTimers }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: () => setShowPauseOtherTimersModal(false)
        }}
      >
        Please pause or end it before starting another, or use the button here to pause all other
        timers.
      </MessageDialog>
    );
  };

  const renderTicketConnectModal = (): ReactNode => {
    return (
      <ConnectTicketsToCallModal
        call={currentCall}
        patient={props.patient}
        virtualPage="call logger"
        isOpen={showConnectTicketsModal}
        onSave={onConnectTicketsSave}
        onClose={toggleConnectTicketsModal}
      />
    );
  };

  const renderHomeCareInstructionsDraftNotSentDialog = (): ReactNode => (
    <MessageDialog
      isOpen={isHomeCareInstructionsDraftNotSentDialogOpen}
      handleClose={toggleHomeCareInstructionsDraftNotSent}
      title="Delete Homecare Instructions & Submit Call"
      id={Dialogs.HomeCareInstructionsDraftNotSent}
      primaryActionProps={{
        text: 'Delete & Submit',
        onClick: () => {
          homeCareInstructionsFormMethods.setValue('text', '');
          toggleHomeCareInstructionsDraftNotSent();
          tryToSubmitCall();
        }
      }}
      secondaryActionProps={{
        text: 'Cancel',
        onClick: toggleHomeCareInstructionsDraftNotSent
      }}
      testHook={patientPageTestSelectors.callLogger.homeCareInstructionsSection.draftNotSentDialog}
    >
      We noticed you wrote some Homecare Instructions, but did not send the text message.
      <br /> <br />
      To submit this call without sending them, click “Submit Call”.
      <br /> <br />
      To go back and send the message, click “Cancel”.
    </MessageDialog>
  );

  const onConnectTicketsSave = (
    connectedTicketIds: number[],
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[],
    shouldResolveAll: boolean
  ) => {
    toggleConnectTicketsModal();

    callLoggingStore.updateCurrentCall({
      ticketIds: new Set<number>(connectedTicketIds),
      callReasons,
      topicsDiscussed
    });
    submitCall(false, false, shouldResolveAll);
  };

  const onClickedPauseOtherTimers = () => {
    callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
    setShowPauseOtherTimersModal(false);
  };

  const minimized = callLoggingStore.isOpenAndMinimized;

  const callViewClasses = classNames('call-view', {
    'active-call': callLoggingStore.isTimerActive(),
    extended: !minimized && isShowingPathways
  });

  const wrapperClasses = classNames('call-logging-wrapper', {
    minimized
  });
  const containerClasses = classNames('call-logging-container', {
    minimized,
    'is-preview-mode': pathwaysStore.isPreviewMode
  });

  const isCallSaveInProgress = networkLabelService.isLoading(
    API_URLS.SAVE_PATIENT_CALL(props.patient.id)
  );

  const isSummaryManuallyEdited = getIsSummaryManuallyEdited();

  return (
    <>
      {renderDeleteCallPrompt()}

      <div className={wrapperClasses}>
        {pathwaysStore.isPreviewMode && (
          <StyledPreviewWarning component="div" variant="badge" color="error">
            PATHWAY PREVIEW: do not submit call or save draft
          </StyledPreviewWarning>
        )}

        <div
          className={containerClasses}
          data-test-hook={patientPageTestSelectors.callLogger.container}
        >
          <CallReasonSelectionModal
            patient={props.patient}
            isOpen={isCallReasonSelectionModalOpen}
            onCancel={toggleCallReasonSelectionModal}
            onSaved={handleCallReasonsSaved}
            initialValue={currentCall.callReasons}
          />
          {pathwaysStore.pathwaysData && (
            <PathwaysView
              isOpen={isShowingPathways}
              setIsOpen={setShowPathways}
              patientId={props.patient.id}
            />
          )}
          <div className={callViewClasses} onClick={minimized ? handleToggleMinimize : null}>
            {renderHelmet()}
            {!minimized && (
              <>
                {renderEditDurationModel()}
                {renderOtherActiveCallDialog()}
                {renderCallDeletedPrompt()}
                {renderInaccurateDurationsDialog()}
                {renderMaxCallTimeReachedDialog()}
                {renderTicketConnectModal()}
                {renderHomeCareInstructionsDraftNotSentDialog()}
              </>
            )}
            {isCallSaveInProgress && (
              <div className="loading-backdrop">
                <Loading primaryColor />
              </div>
            )}

            <CallLoggingHeader
              patient={props.patient}
              minimized={minimized}
              onCloseClicked={handleCloseBtnClicked}
              onSaveDraftClicked={onSaveDraftClick}
              onToggleMinimizeClicked={handleToggleMinimize}
              isSaveInProgress={isSaveInProgress}
              isSummaryManuallyEdited={isSummaryManuallyEdited}
            />
            {!minimized && (
              <>
                {pathwaysStore.pathwaysData ? (
                  <FormProvider {...homeCareInstructionsFormMethods}>
                    <CallLoggingBody
                      patient={props.patient}
                      userPreferences={userPreferences}
                      auditTrail={auditTrail}
                    />
                  </FormProvider>
                ) : (
                  <Loading primaryColor />
                )}
              </>
            )}

            <FormProvider {...smartSummaryFormMethods}>
              <CallLoggingFooter
                isSaveInProgress={isSaveInProgress}
                onSubmitCallClicked={onSubmitCallClicked}
                onEditCallDurationClicked={openEditDurationModal}
                isOtherTimersRunning={showPauseOtherTimersModal}
                onResumeCallWithOtherTimers={() => setShowPauseOtherTimersModal(true)}
                shouldSendCallToEmr={shouldSendCallToEmr}
                onSendCallToEmrChange={(shouldSendCallToEmr) =>
                  setShouldSendCallToEmr(shouldSendCallToEmr)
                }
                setOriginalGeneratedSmartSummary={setOriginalGeneratedSmartSummary}
                isSummaryManuallyEdited={isSummaryManuallyEdited}
                userPreferences={userPreferences}
                setUserPreferences={setUserPreferences}
                hasServerError={props.hasServerError}
              />
            </FormProvider>
          </div>
        </div>
        {pathwaysStore.isPreviewMode && (
          <StyledBottomPreviewWarning component="div" variant="badge" color="error">
            PATHWAY PREVIEW: do not submit call or save draft
          </StyledBottomPreviewWarning>
        )}
      </div>
    </>
  );
};

const StyledPreviewWarning = styled(Text)`
  height: 60px;
  width: 100%;
  background-color: #ffe55b;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 100;
`;

const StyledBottomPreviewWarning = styled(StyledPreviewWarning)`
  position: fixed;
  bottom: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
`;

export const CallLogging = observer(CallContainer);
