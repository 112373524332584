import { AiSuggestionType } from 'fetchers/TwoWayMessagesFetcher';

import { AnalyticsService, AnalyticEventName, AnalyticEventAction } from '../';

interface Properties {
  suggestion_id: string | null;
  suggestion_uuid: string;
  value: AiSuggestionType;
}

export const trackMessageSuggestionSelectAnalyticsEvent = ({
  suggestion_id,
  suggestion_uuid,
  value
}: Properties) => {
  AnalyticsService.trackEvent({
    name: AnalyticEventName.SelectedMessageSuggestion,
    properties: { action: AnalyticEventAction.Select, suggestion_id, suggestion_uuid, value }
  });
};
