import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Send | AnalyticEventAction.Cancel;
type Value = 'blocked' | undefined;

interface Properties {
  action: Action;
  value?: Value;
  type: '1 Way SMS' | '2 Way SMS';
  is_using_ai_suggestions?: boolean;
  is_manually_edited?: boolean;
}

export const trackSendSmsAnalyticsEvent = ({ action, type, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SendSms,
    properties: {
      action,
      type,
      ...optionalProps
    }
  });
};
