import { ActionMeta } from 'react-select';

import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';

import { AnalyticEventAction, AnalyticEventName, AnalyticsService } from '../';

type Action =
  | AnalyticEventAction.Add
  | AnalyticEventAction.Update
  | AnalyticEventAction.Remove
  | AnalyticEventAction.Clear;

type Type =
  | 'checkbox'
  | 'radio'
  | 'dropdown'
  | 'open text'
  | 'date'
  | 'toggle'
  | 'multiselection'
  | 'switch'
  | 'slider'
  | 'number';

interface Properties {
  action: Action;
  type: Type;
  value?: Value;
  virtual_page?: 'call logger' | 'add rule' | 'edit rule' | 'report protocol modal';
  source?: 'keyboard' | null;
  selected_value?: string;
}

type Value =
  | CheckboxInputName
  | RadioInputName
  | OpenTextInputName
  | DateInputName
  | ToggleButtonInputName
  | DropdownInputName
  | MultiSelectInputName
  | SwitchInputName
  | SliderInputName
  | NumberInputName;

export const trackInputUsageAnalyticsEvent = ({ action, type, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.InputUsage,
    properties: { action, type, ...optionalProps }
  });
};

////////////// Radio //////////////
type RadioInputName = 'Callback Contact' | 'Include smart summary';

////////////// Switch //////////////
type SwitchInputName = 'Automatic Frequency' | 'Automatic Protocol';

////////////// Switch //////////////
type SliderInputName = 'Distress Level';

////////////// Checkbox //////////////
type CheckboxInputName =
  | 'Service Consent with financial assistance'
  | 'Service Consent without financial assistance'
  | 'Copay Consent'
  | 'Read Terms'
  | 'Callback Hippa'
  | 'Homecare Instructions Consent';

export const trackCheckboxInputUsageAnalyticsEvent = (
  hasPreviousValue: boolean,
  inputName: CheckboxInputName
) => {
  trackInputUsageAnalyticsEvent({
    action: hasPreviousValue ? AnalyticEventAction.Remove : AnalyticEventAction.Add,
    type: 'checkbox',
    value: inputName
  });
};

////////////// Number //////////////
type NumberInputName = 'Duration' | 'Report Requests';

export const trackNumberInputUsageAnalyticsEvent = (
  currentValue: string,
  previousValue: string,
  inputName: NumberInputName
) => {
  if (currentValue === previousValue) {
    return;
  }
  const hasCurrentValue = Boolean(currentValue);
  const hasPreviousValue = Boolean(previousValue);

  let eventAction:
    | AnalyticEventAction.Add
    | AnalyticEventAction.Clear
    | AnalyticEventAction.Update = AnalyticEventAction.Update;

  if (!hasCurrentValue && hasPreviousValue) {
    eventAction = AnalyticEventAction.Clear;
  }

  if (!hasPreviousValue && hasCurrentValue) {
    eventAction = AnalyticEventAction.Add;
  }

  trackInputUsageAnalyticsEvent({
    action: eventAction,
    type: 'number',
    value: inputName
  });
};

////////////// Open Text //////////////
type OpenTextInputName =
  | 'Callback Phone Number'
  | 'ticket notes'
  | 'First Name'
  | 'Last Name'
  | 'Email'
  | 'Phone Number'
  | 'My Note Section'
  | 'Medical Record Number'
  | 'Callback Name'
  | 'My Smart Summary'
  | 'Homecare Instructions Phone Number'
  | 'Homecare Instructions Text Message';

export const trackOpenTextInputUsageAnalyticsEvent = (
  currentValue: string,
  valueAfterFocus: string,
  inputName: OpenTextInputName,
  additionalProperties?: { [key: string]: any }
) => {
  if (currentValue === valueAfterFocus) {
    return;
  }

  const eventAction = getEventActionOnTextChange(valueAfterFocus, currentValue);

  trackInputUsageAnalyticsEvent({
    action: eventAction,
    value: inputName,
    type: 'open text',
    ...additionalProperties
  });
};

export const getEventActionOnTextChange = (
  valueAfterFocus: string,
  currentValue: string
): AnalyticEventAction.Add | AnalyticEventAction.Remove | AnalyticEventAction.Update => {
  const hasValueAfterFocus = Boolean(valueAfterFocus);
  const hasCurrentValue = Boolean(currentValue);
  let eventAction:
    | AnalyticEventAction.Add
    | AnalyticEventAction.Remove
    | AnalyticEventAction.Update = AnalyticEventAction.Remove;

  if (!hasValueAfterFocus && hasCurrentValue) {
    eventAction = AnalyticEventAction.Add;
  }

  if (hasValueAfterFocus && hasCurrentValue && valueAfterFocus !== currentValue) {
    eventAction = AnalyticEventAction.Update;
  }

  return eventAction;
};

////////////// Date //////////////
type DateInputName = 'Date of Birth' | 'Activation of Protocol' | 'End Date';

export const trackDateInputUsageAnalyticsEvent = (
  currentValue: string,
  previousValue: string,
  inputName: DateInputName
) => {
  if (currentValue === previousValue) {
    return;
  }
  const hasCurrentValue = Boolean(currentValue);
  const hasPreviousValue = Boolean(previousValue);

  let eventAction:
    | AnalyticEventAction.Add
    | AnalyticEventAction.Clear
    | AnalyticEventAction.Update = AnalyticEventAction.Update;

  if (!hasCurrentValue && hasPreviousValue) {
    eventAction = AnalyticEventAction.Clear;
  }

  if (!hasPreviousValue && hasCurrentValue) {
    eventAction = AnalyticEventAction.Add;
  }

  trackInputUsageAnalyticsEvent({ action: eventAction, value: inputName, type: 'date' });
};

////////////// Toggle Button //////////////
type ToggleButtonInputName = 'Patient Enrollment Status' | 'Threshold' | 'Week Days';

export const trackToggleButtonInputUsageAnalyticsEvent = (
  previousValue: string,
  inputName: ToggleButtonInputName,
  additionalAnalyticsProperties?: Partial<Properties>
) => {
  const hasPreviousValue = Boolean(previousValue);

  let eventAction: AnalyticEventAction.Update | AnalyticEventAction.Add =
    AnalyticEventAction.Update;

  if (!hasPreviousValue) {
    eventAction = AnalyticEventAction.Add;
  }

  trackInputUsageAnalyticsEvent({
    action: eventAction,
    type: 'toggle',
    value: inputName,
    ...(additionalAnalyticsProperties || {})
  });
};

////////////// Dropdown //////////////
export type DropdownInputName =
  | 'Provider'
  | 'Location'
  | 'Assignee'
  | 'Urgency'
  | 'Relationship to Patient'
  | 'Sex'
  | 'Language'
  | 'Primary Provider'
  | 'Primary Location'
  | 'Tags'
  | 'Report Protocol Activation'
  | 'Phone Type'
  | 'Opt Out Reason'
  | 'Ticket Types Sub Dropdown'
  | 'Rule Applies to'
  | 'Trigger Type'
  | 'Report Protocol'
  | 'Drug'
  | 'Type'
  | 'End of Protocol'
  | 'Duration Unit';

export const trackDropdownInputUsageAnalyticsEvent = (
  actionMeta: ActionMeta<any>,
  inputName: DropdownInputName,
  isKeyboardSource: boolean,
  hasPreviousValue: boolean = true,
  additionalAnalyticsProperties?: Partial<Properties>
) => {
  let eventAction:
    | AnalyticEventAction.Update
    | AnalyticEventAction.Clear
    | AnalyticEventAction.Add = AnalyticEventAction.Update;

  if (actionMeta.action === SelectActionMetaName.Clear) {
    eventAction = AnalyticEventAction.Clear;
  }

  if (actionMeta.action === SelectActionMetaName.Select && !hasPreviousValue) {
    eventAction = AnalyticEventAction.Add;
  }

  trackInputUsageAnalyticsEvent({
    action: eventAction,
    value: inputName,
    type: 'dropdown',
    source: isKeyboardSource ? 'keyboard' : null,
    ...(additionalAnalyticsProperties || {})
  });
};

////////////// Multi Select //////////////
type MultiSelectInputName =
  | 'Ticket Types Main Dropdown'
  | 'Ticket Types Sub Dropdown'
  | 'Tags'
  | 'Search by Issue'
  | 'Specific Patients'
  | 'Home Care Instructions Contacts';

export const trackMultiSelectionInputUsageAnalyticsEvent = (
  actionMeta: ActionMeta<any>,
  inputName: MultiSelectInputName,
  isKeyboardSource: boolean,
  additionalAnalyticsProperties?: Partial<Properties>
) => {
  let eventAction:
    | AnalyticEventAction.Add
    | AnalyticEventAction.Remove
    | AnalyticEventAction.Clear = AnalyticEventAction.Add;

  if (actionMeta.action === SelectActionMetaName.Clear) {
    eventAction = AnalyticEventAction.Clear;
  }

  if (actionMeta.action === SelectActionMetaName.Remove) {
    eventAction = AnalyticEventAction.Remove;
  }

  trackInputUsageAnalyticsEvent({
    action: eventAction,
    type: 'multiselection',
    value: inputName,
    source: isKeyboardSource ? 'keyboard' : null,
    ...(additionalAnalyticsProperties || {})
  });
};
