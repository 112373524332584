import { forwardRef } from 'react';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AiMessageSuggestion } from 'fetchers/TwoWayMessagesFetcher';

import { AiSuggestionCard } from './AiSuggestionCard';

interface Props {
  onSuggestionSelect: (suggestion: AiMessageSuggestion) => void;
  onSuggestionHover?: (suggestion: AiMessageSuggestion) => void;
  onSuggestionLeave?: () => void;
  selectedSuggestionId: string | null;
  currentMessageText: string;
  suggestions: AiMessageSuggestion[];
  isLoading: boolean;
  hasError: boolean;
  onTryAgain: () => void;
}

export const AiSuggestions = forwardRef<HTMLDivElement, Props>(
  (
    {
      onSuggestionSelect,
      onSuggestionHover,
      onSuggestionLeave,
      selectedSuggestionId,
      currentMessageText,
      suggestions,
      isLoading,
      hasError,
      onTryAgain
    },
    ref
  ) => {
    const hasSuggestions = suggestions.length >= 2;

    const handleSuggestionClick = (suggestion: AiMessageSuggestion) => {
      onSuggestionSelect(suggestion);
    };

    let gridItems = null;

    if (isLoading) {
      gridItems = (
        <>
          <Grid item xs={6}>
            <AiSuggestionCard isLoading />
          </Grid>
          <Grid item xs={6}>
            <AiSuggestionCard isLoading />
          </Grid>
        </>
      );
    }

    if (hasError) {
      gridItems = (
        <>
          <Grid item xs={6}>
            <AiSuggestionCard isError errorType="failed" />
          </Grid>
          <Grid item xs={6}>
            <AiSuggestionCard isError errorType="retry" onTryAgain={onTryAgain} />
          </Grid>
        </>
      );
    }

    if (hasSuggestions) {
      gridItems = (
        <>
          <Grid item xs={6}>
            <AiSuggestionCard
              suggestion={suggestions[0]}
              isSelected={
                selectedSuggestionId === suggestions[0].uuid &&
                currentMessageText === suggestions[0].suggestionText
              }
              onClick={handleSuggestionClick}
              {...(onSuggestionHover
                ? { onMouseEnter: () => onSuggestionHover(suggestions[0]) }
                : {})}
              {...(onSuggestionLeave ? { onMouseLeave: onSuggestionLeave } : {})}
            />
          </Grid>
          <Grid item xs={6}>
            <AiSuggestionCard
              suggestion={suggestions[1]}
              isSelected={
                selectedSuggestionId === suggestions[1].uuid &&
                currentMessageText === suggestions[1].suggestionText
              }
              onClick={handleSuggestionClick}
              {...(onSuggestionHover
                ? { onMouseEnter: () => onSuggestionHover(suggestions[1]) }
                : {})}
              {...(onSuggestionLeave ? { onMouseLeave: onSuggestionLeave } : {})}
            />
          </Grid>
        </>
      );
    }

    return (
      <StyledContainer>
        <StyledFlexGrid container gap={8} flexWrap="nowrap" ref={ref}>
          {gridItems}
        </StyledFlexGrid>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Box)`
  width: 100%;
  background-color: transparent;
`;

const StyledFlexGrid = styled(Grid)`
  display: flex;
  align-items: stretch;

  & > .MuiGrid-item {
    display: flex;
  }
`;
