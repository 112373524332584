// @ts-strict-ignore
import { ErrorName, TicketDoublePickupFailure, transformErrorUiProps } from 'errors';
import { castArray } from 'lodash/fp';

import HttpService from 'services/HttpService';

import { RequestConfig } from 'utils/http.utils';
import { sanitizeFiltersOrQuery } from 'utils/serverFiltersUtils';

import { API_LABELS, API_URLS } from 'constants/apiUrls';

import { CommentsParser } from 'parsers/CommentsParser';

import { TicketsParser } from 'parsers/TicketsParser';

import { CommentableType } from 'models/Comment';
import Ticket, { TicketStatus } from 'models/Ticket';

import { TicketsQueryRequestParams } from 'views/Filters/filters.types';

import { TicketMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';

export interface IPatientContactRequestAttributes {
  id?: number;
  name: string;
  relationship?: string;
  phoneNumber: string;
  hippaAuthDate?: Date | null;
}

export interface TicketRequest {
  urgency: number;
  notes: string;
  providerId: string;
  locationId: number;
  assigneeId?: number | null;
  ticketTypeId: number; // old api - still called ticketTypeId but should be named categoryId
  ticketSubTypeIds: number[];
}

export interface IOperatorTicketsCreateRequest {
  patientId?: number;
  phoneExtension?: string;
  contactInfo?: IPatientContactRequestAttributes | null;
  tickets: TicketRequest[];
  creationTimeInSeconds?: number;
}

export interface IOperatorTicketUpdateRequest {
  patientId?: number;
  phoneExtension?: string;
  contactInfo?: IPatientContactRequestAttributes | null;
  ticket: TicketRequest;
}

export interface ITicketCommentCreateRequest {
  commentableId: number;
  commentableType: CommentableType;
  userId: number;
  text: string;
}

export interface ICreateTicketResult {
  tickets: number[];
  patientId: number;
}

export interface TicketResolutionResponse {
  optedOutErrorTicketIds: number[];
  generalErrorTicketIds: number[];
  resolvedTicketIds: number[];
  pdfFailedTicketIds: number[];
  pdfFailedCallIds: number[];
  pdfSucceededTicketIds: number[];
  pdfSucceededCallIds: number[];
  createdCommentsCount: number;
}

export interface TicketCheckStatusResponse {
  status: TicketStatus;
}

export interface ResolveAllTicketsRequestBody {
  ticketIds: number[];
}

const httpTicketService = new HttpService('ticket', true);
const httpCommentService = new HttpService('comment', true);

export class TicketsFetcher {
  static async deleteTicket(ticketId: number) {
    return await httpTicketService.delete({
      url: API_URLS.TICKET_DELETE(ticketId)
    });
  }

  static async createTickets(data: IOperatorTicketsCreateRequest): Promise<ICreateTicketResult> {
    return await httpTicketService.post({
      url: API_URLS.TICKETS,
      data
    });
  }

  static async updateTicket(id: number, data: IOperatorTicketUpdateRequest) {
    return await httpTicketService.put({
      url: API_URLS.UPDATE_TICKET(id),
      data
    });
  }

  static async createTicketComment(data: ITicketCommentCreateRequest) {
    return await httpCommentService.post({
      url: API_URLS.COMMENTS,
      networkLabel: API_LABELS.COMMENTS(data.commentableId),
      data,
      transformResponse: CommentsParser.parseComment
    });
  }

  static async resolveTickets(
    ticketId: number | number[],
    comment: string = '',
    allowResolveLocalPatientTicket: boolean = false
  ) {
    const ticketIds = castArray(ticketId);
    return await httpTicketService.post({
      url: API_URLS.RESOLVE_TICKETS,
      networkLabel: API_LABELS.BULK_RESOLVE_TICKETS(ticketIds),
      data: { ticketIds, allowResolveLocalPatientTicket, ...(comment && { comment }) },
      transformResponse: TicketsParser.parseResolveTicketsResponse
    });
  }

  static async resolveAllTickets(
    requestBody: ResolveAllTicketsRequestBody
  ): Promise<TicketResolutionResponse> {
    return await httpTicketService.post({
      url: API_URLS.RESOLVE_TICKETS,
      data: requestBody,
      transformResponse: TicketsParser.parseResolveTicketsResponse,
      transformError: transformErrorUiProps('Failed to resolve ticket(s)')
    });
  }

  static async assignTicket(ticketId: number, doctorId: number | null, isReassign: boolean) {
    return await httpTicketService.post({
      url: API_URLS.ASSIGN_TICKET(ticketId),
      data: { doctorId, isReassign },
      transformError: (error) => {
        if (error.name === ErrorName.TicketDoublePickup) {
          const serverData = error.httpFailure.serverData as TicketDoublePickupFailure;
          const { assignee } = serverData;
          error.ui.title = `Item Already Assigned to ${assignee}.`;
          error.ui.description =
            'Data usually refreshes in the background automatically. However, if you’re not on the first page of a Work Queue section, please use the Refresh button in Canopy or your browser.';
        } else {
          error.ui.title = 'Failed to reassign ticket';
        }
        return error;
      }
    });
  }

  static async getTicketHistory(ticketId: number, requestConfig?: RequestConfig) {
    return await httpTicketService.get({
      ...requestConfig,
      url: API_URLS.TICKET_HISTORY(ticketId),
      transformResponse: TicketsParser.parseHistory,
      transformError: (error) => {
        error.name = ErrorName.FailedToGetHistory;
        error.ui.title = 'Failed to get history';
        return error;
      }
    });
  }

  static async searchTickets(
    ticketsQueryRequestParams?: TicketsQueryRequestParams,
    cancellationKey?: string
  ) {
    const data = sanitizeFiltersOrQuery(ticketsQueryRequestParams);
    return await httpTicketService.post<Ticket[]>({
      url: API_URLS.SEARCH_TICKETS,
      data,
      networkLabel: data?.patientId
        ? API_LABELS.TICKETS_BY_PATIENT(data.patientId)
        : API_URLS.SEARCH_TICKETS,
      transformResponse: TicketsParser.parseSearchTicketsResult,
      transformError: transformErrorUiProps('Tickets search failed'),
      cancellationKey
    });
  }

  static async ticketCheckStatus(ticketId: number) {
    return await httpTicketService.get<TicketCheckStatusResponse>({
      url: API_URLS.TICKET_CHECK_STATUS(ticketId)
    });
  }

  static async getTicketMessages(ticketId: number) {
    return await httpTicketService.get<TicketMessage[]>({
      url: API_URLS.TICKET_MESSAGES(ticketId),
      transformError: transformErrorUiProps('Failed to get ticket messages')
    });
  }
}

export default TicketsFetcher;
