import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

export interface SendMessageToPatientBody {
  ticketId: number;
  message: string;
  suggestion?: SuggestionSelection;
}

interface SuggestionSelection {
  suggestionSelectedUuid?: string;
  suggestionId: string;
  isEdited?: boolean;
}

export interface MarkTicketMessagesAsSeenBody {
  ids: number[];
}

export enum AiSuggestionType {
  Affirmative = 'Affirmative',
  Negative = 'Negative'
}

export interface AiMessageSuggestion {
  suggestionText: string;
  suggestionType: AiSuggestionType;
  suggestionDescription?: string;
  uuid: string;
}

interface AiMessageSuggestionResponse {
  messageSuggestions: AiMessageSuggestion[];
  suggestionId: string;
}

const httpService = new HttpService('two way messages');

export class TwoWayMessagesFetcher {
  static async retryTicketMessage(messageId: number) {
    return await httpService.patch({
      url: API_URLS.RETRY_TICKET_MESSAGE(messageId),
      transformError: transformErrorUiProps('Failed to retry ticket message')
    });
  }

  static async sendMessageToPatient(body: SendMessageToPatientBody) {
    return await httpService.post({
      url: API_URLS.SEND_MESSAGE,
      data: body,
      transformError: transformErrorUiProps('Failed to send message to patient')
    });
  }

  static async markTicketMessagesAsSeen(body: MarkTicketMessagesAsSeenBody) {
    return await httpService.post({
      url: API_URLS.MARK_TICKET_MESSAGES_AS_SEEN,
      data: body,
      transformError: transformErrorUiProps('Failed to mark ticket messages as seen')
    });
  }

  static async deleteUndeliveredMessages(ticketIds: number[]) {
    return await httpService.post({
      url: API_URLS.DELETE_UNDELIVERED_MESSAGES,
      data: {
        ticketIds
      },
      transformError: transformErrorUiProps('Failed to delete undelivered messages')
    });
  }

  static async getMessageSuggestions(ticketId: number): Promise<AiMessageSuggestionResponse> {
    return await httpService.get({
      url: API_URLS.AI_MESSAGE_SUGGESTIONS(ticketId),
      transformError: transformErrorUiProps('Failed to fetch AI message suggestions')
    });
  }
}
