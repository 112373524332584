import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { Box, useTheme } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { trackSendSmsAnalyticsEvent } from 'analytics/events/send-sms';

import { observer } from 'mobx-react';

import { FormProvider, useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { TwoWayMessagesFetcher } from 'fetchers/TwoWayMessagesFetcher';

import { showToast } from 'utils/UserMessageUtils';

import Patient, { IPatientContact, PatientType } from 'models/Patient';

import { PhoneType } from 'models/PhoneNumberDetails';

import Ticket from 'models/Ticket';

import FormTextAreaField from 'components/Forms/FormTextAreaField';

import Icon from 'components/Icons/Icon';
import { usePatientModel } from 'components/Patient/usePatientModel';
import { TicketClinicianMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessage';
import {
  TicketMessage,
  TicketMessageType
} from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';
import { getTicketMessagesContactFormattedName } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.utils';
import { TicketPatientMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketPatientMessage/TicketPatientMessage';
import { TicketPatientContactType } from 'components/Ticket/TicketRow/TicketContact/TicketContact.types';
import { Tooltip } from 'components/Tooltip';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

interface SendMessageForm {
  message: string;
}

export const BaseTicketMessages: FC<Props> = observer(
  ({ ticket, ticketIndex, ticketSectionCurrentPage }) => {
    const { ticketsStore } = useStores();
    const [ticketMessages, setTicketMessages] = useState<TicketMessage[]>(ticket.messages || []);
    const [retryingMessageIds, setRetryingMessageIds] = useState<number[]>([]);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const patient = usePatientModel(ticket.patientId);
    const { palette } = useTheme();
    const sendMessageForm = useForm<SendMessageForm>({
      defaultValues: {
        message: ''
      }
    });

    const fetchTicketMessages = useCallback(
      async function fetchTicketMessages() {
        const messages = await ticketsStore.fetchMessagesForTicket(ticket);
        setTicketMessages(messages);
        return messages;
      },
      [ticket, ticketsStore]
    );

    useEffect(
      function init() {
        async function initMessages() {
          const messages = await fetchTicketMessages();
          const unseenMessageIds = messages
            .filter(
              (message) =>
                (message.senderType === TicketMessageType.Patient ||
                  message.senderType === TicketMessageType.Contact) &&
                !message.isSeen
            )
            .map((message) => message.id);

          if (unseenMessageIds.length) {
            await TwoWayMessagesFetcher.markTicketMessagesAsSeen({
              ids: unseenMessageIds
            });
          }
          ticket.clearTicketNewMessagesCount();
        }

        initMessages();
      },
      [ticket, fetchTicketMessages]
    );

    async function sendTicketMessage(formValue: SendMessageForm) {
      trackSendSmsAnalyticsEvent({ action: AnalyticEventAction.Send, type: '2 Way SMS' });

      try {
        setIsSendingMessage(true);
        await TwoWayMessagesFetcher.sendMessageToPatient({
          message: formValue.message,
          ticketId: ticket.id
        });
        showToast({
          message: `Sending Message to ${ticketMessageContactFormattedName}...`
        });
        sendMessageForm.reset();
        await fetchTicketMessages();
      } finally {
        setIsSendingMessage(false);
      }
    }

    async function retryMessage(messageId: number) {
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.TryAgain,
        ticket_id: ticket.id,
        patient_id: ticket.patientId,
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });

      try {
        setRetryingMessageIds([...retryingMessageIds, messageId]);
        await TwoWayMessagesFetcher.retryTicketMessage(messageId);
      } finally {
        setRetryingMessageIds(retryingMessageIds.filter((id) => id !== messageId));
        await fetchTicketMessages();
      }
    }

    function getSendMessageInputDisabledDetails(): {
      disabledText: string;
      tooltipText?: string | ReactNode;
    } {
      if (ticket.isResolved) {
        return { disabledText: 'Ticket Resolved' };
      }

      if (!patient?.hasMessagingConsent && patient?.type === PatientType.Regular) {
        return {
          disabledText: 'Communication Consent Not Provided',
          tooltipText:
            'To send a message, denote the patient’s consent via Create Ticket page, or in the Homecare Instructions section of the Call Logger.'
        };
      }

      if (patient?.openConversationTicketId && ticket.id !== patient?.openConversationTicketId) {
        return {
          disabledText: 'Patient already has an active conversation in another ticket.',
          tooltipText:
            'To send a message here, resolve the other ticket from which this patient is currently being messaged.'
        };
      }

      if (
        (contact.type === TicketPatientContactType.Self && Boolean(contact.model.isSMSDisabled)) ||
        (contact.type === TicketPatientContactType.Contact && Boolean(contact.model.smsOptedOutAt))
      ) {
        return {
          disabledText: 'Cannot Message - Opted Out of SMS',
          tooltipText: (
            <>
              This occurs when somebody has replied STOP to an SMS message from Canopy.
              <br />
              <br />
              To opt back in, this patient / Callback Contact must text START to that same number.
            </>
          )
        };
      }

      if (Boolean(contact.model.phoneType === PhoneType.landline)) {
        return {
          disabledText: 'Cannot Message Landline',
          tooltipText:
            'Landline phone numbers are not supported, as message notifications arrive via SMS.'
        };
      }

      return { disabledText: '' };
    }

    function getContact():
      | { type: TicketPatientContactType.Contact; model: IPatientContact }
      | { type: TicketPatientContactType.Self; model: Patient } {
      if (ticket.patientHasContact) {
        const patientContact = patient?.getContactById(ticket.operatorTicket!.patientContactId);

        if (patientContact) {
          return { type: TicketPatientContactType.Contact, model: patientContact };
        }
      }

      return { type: TicketPatientContactType.Self, model: patient! };
    }

    const contact = getContact();
    const ticketMessageContactFormattedName = getTicketMessagesContactFormattedName(contact);
    const { disabledText, tooltipText } = getSendMessageInputDisabledDetails();

    return (
      <StyledContainer>
        <Box display="flex" alignItems="center" gap={8} mb={20}>
          <Text color="secondary">PATIENT MESSAGES</Text>

          <Tooltip
            label={<Icon.Info display="flex" color={palette.text.secondary} />}
            maxWidth={342}
          >
            <Box p={20}>
              <StyledInfoList>
                <li>Message is sent via SMS to the ticket’s Callback Contact.</li>
                <li>Patient may reply until the ticket is resolved.</li>
              </StyledInfoList>
            </Box>
          </Tooltip>
        </Box>

        <StyledTicketMessagesContainer>
          {ticketMessages.map((message) => (
            <Box key={message.id} pb={20}>
              {message.senderType === TicketMessageType.Clinician && (
                <TicketClinicianMessage
                  message={message}
                  onRetryMessage={retryMessage}
                  isLoading={retryingMessageIds.includes(message.id)}
                />
              )}

              {(message.senderType === TicketMessageType.Patient ||
                message.senderType === TicketMessageType.Contact) && (
                <TicketPatientMessage message={message} />
              )}
            </Box>
          ))}
        </StyledTicketMessagesContainer>

        <FormProvider {...sendMessageForm}>
          <StyledInputContainer>
            <Tooltip
              disabled={!Boolean(tooltipText)}
              maxWidth={342}
              placement="bottom"
              label={
                <StyledFormTextAreaField
                  name="message"
                  rounded
                  disabled={Boolean(disabledText) || isSendingMessage}
                  placeholder={
                    disabledText ? disabledText : `SMS to ${ticketMessageContactFormattedName}`
                  }
                  isRequired
                >
                  <StyledInputAction>
                    {Boolean(tooltipText) ? (
                      <Icon.Info color={palette.secondary.dark} />
                    ) : (
                      <StyledSendIcon
                        height={20}
                        width={20}
                        onClick={() =>
                          isSendingMessage
                            ? null
                            : sendMessageForm.handleSubmit(sendTicketMessage)()
                        }
                        color={palette.natural.black}
                      />
                    )}
                  </StyledInputAction>
                </StyledFormTextAreaField>
              }
            >
              <Box p={20}>{tooltipText}</Box>
            </Tooltip>

            <StyledPhiWarning className="phi-warning" ml={12}>
              <Text variant="helper-text" color="error">
                Do not include PHI
              </Text>
            </StyledPhiWarning>
          </StyledInputContainer>
        </FormProvider>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary.alternate};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(12)};
    border-radius: ${theme.borderRadius.large};
  `
);

const StyledInfoList = styled('ul')(
  ({ theme }) => css`
    padding-left: ${theme.spacing(20)};
    margin: 0;
  `
);

const StyledTicketMessagesContainer = styled(Box)(
  ({ theme }) => css`
    > div:not(:first-child) {
      border-top: 1px solid ${theme.palette.natural.border};
      padding-top: ${theme.spacing(20)};
    }
  `
);

const StyledInputContainer = styled(Box)(
  ({ theme }) => css`
    position: relative;

    .input-area textarea:not(:disabled):focus-within,
    .input-area textarea:not(:disabled):active {
      border-color: ${theme.palette.secondary.dark};

      + div svg {
        color: ${theme.palette.secondary.dark};
        opacity: 1;
        cursor: pointer;
      }
    }

    :has(.input-area textarea:not(:disabled):focus-within) {
      div.phi-warning {
        visibility: visible;
      }
    }
  `
);

const StyledFormTextAreaField = styled(FormTextAreaField)(
  ({ theme }) => css`
    position: relative;

    .input-area textarea {
      min-height: 47px;
      field-sizing: content;
      height: fit-content;
      padding: ${theme.spacing(12, 36, 12, 12)};
      font-size: ${theme.typography.body1.fontSize};
      line-height: 21px;

      ::placeholder {
        font-size: ${(theme.typography as any)['form-text'].fontSize};
      }
    }
  `
);

const StyledPhiWarning = styled(Box)`
  visibility: hidden;
  height: fit-content;
`;

const StyledInputAction = styled(Box)`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSendIcon = styled(Icon.Send)`
  opacity: 0.4;
`;
