// @ts-strict-ignore
import { find, groupBy } from 'lodash';

import { CallResolutionResponse } from 'fetchers/CallsFetcher';

import { parseDate } from 'utils/DateUtils';

import Call, { CallDurationInterval } from 'models/Call';
import CallPathwayAnswer from 'models/CallPathwayAnswer';
import OrderedCallPathwayAnswer from 'models/OrderedCallPathwayAnswer';
import { PathwayQuestionTypes } from 'models/PathwayTemplates';

import { disabledSummaryText } from 'views/Widgets/CallLogging/CallLoggingSummary';

export class CallsParser {
  static parseCall(unparsedCall: any) {
    const topicsDiscussed = unparsedCall.topics_discussed || unparsedCall.topicsDiscussed;
    const includeSummaryInEmrNote =
      unparsedCall.include_summary_in_emr_note || unparsedCall.includeSummaryInEmrNote;
    return new Call({
      id: unparsedCall.id,
      callDuration: unparsedCall.call_duration_seconds || unparsedCall.callDurationSeconds,
      doctorId: unparsedCall.doctor_id || unparsedCall.doctorId,
      patientId: unparsedCall.patient_id || unparsedCall.patientId,
      note: unparsedCall.note,
      lastReportId: unparsedCall.last_report || unparsedCall.lastReport,
      patientInitiated: Boolean(unparsedCall.patient_initiated || unparsedCall.patientInitiated),
      createdAt: parseDate(unparsedCall.created_at || unparsedCall.createdAt),
      updatedAt: parseDate(unparsedCall.updated_at || unparsedCall.updatedAt),
      durationIntervals: (unparsedCall.call_intervals || unparsedCall.callIntervals)?.map(
        (interval: any) =>
          new CallDurationInterval(
            parseDate(interval.started_at || interval.startedAt),
            parseDate(interval.ended_at || interval.endedAt),
            interval.id,
            interval.type.toLowerCase(),
            interval.doctor_details || interval.doctorDetails,
            interval.uuid,
            Boolean(interval.is_call_attempt || interval.isCallAttempt)
          )
      ),
      auditTrail: unparsedCall.audit_trail || unparsedCall.auditTrail,
      callReasons: unparsedCall.call_reasons || unparsedCall.callReasons,
      topicsDiscussed: topicsDiscussed ? topicsDiscussed : [],
      info: unparsedCall.info,
      orderedPathwayAnswers: unparsedCall.pathwayAnswers
        ? unparsedCall.pathwayAnswers.map((unparsedPathwayAnswer: any) =>
            CallsParser.parseCallPathwayAnswer(unparsedPathwayAnswer)
          )
        : [],
      isDraft: Boolean(unparsedCall.is_draft || unparsedCall.isDraft),
      ticketIds: new Set<number>(
        unparsedCall.ticketsToCalls?.map(
          (ticketToCall: any) => ticketToCall.ticket_id || ticketToCall.ticketId
        )
      ),
      documentId: unparsedCall.document_id || unparsedCall.documentId,

      summary:
        includeSummaryInEmrNote === 'no' || includeSummaryInEmrNote === 'dontOfferAgain'
          ? disabledSummaryText
          : unparsedCall.summary || '',
      includeSummaryInEmrNote: includeSummaryInEmrNote || '',
      smartSummaryId: unparsedCall.summary_id || unparsedCall.summaryId || null,
      isSummaryManuallyEdited: Boolean(
        unparsedCall.is_summary_manually_edited || unparsedCall.isSummaryManuallyEdited
      ),
      clinician: unparsedCall.clinician,
      homeCareInstructions: unparsedCall.homeCareInstructions
        ? CallsParser.parseHomeCareInstructions(unparsedCall.homeCareInstructions)
        : [],
      homeCareInstructionsDraft: unparsedCall.home_care_instructions_draft ||
        unparsedCall.homeCareInstructionsDraft || {
          text: '',
          phoneNumber: ''
        }
    });
  }
  static parseHomeCareInstructions(unparsedHomeCareInstructions: any) {
    return Object.values(
      groupBy(
        unparsedHomeCareInstructions,
        (notification) => `${notification.text}|${notification.contactId || 'null'}`
      )
    ).map((group) => ({
      ids: group.map((notification) => notification.id),
      text: group[0].text,
      patientId: group[0].patientId,
      phoneNumber: find(group, 'phoneNumber')?.phoneNumber || null,
      email: find(group, 'email')?.email || null,
      createdAt: group[0].createdAt,
      contactId: group[0].contactId
    }));
  }

  static parseCallPathwayAnswer(unparsedPathwayAnswer: any): CallPathwayAnswer {
    // TODO - use proper type and remove snake case when no more V1 Instances
    const questionType = unparsedPathwayAnswer.question_type || unparsedPathwayAnswer.questionType;
    return new OrderedCallPathwayAnswer(
      new CallPathwayAnswer(
        unparsedPathwayAnswer.pathway_id || unparsedPathwayAnswer.pathwayId,
        unparsedPathwayAnswer.pathway_name || unparsedPathwayAnswer.pathwayName,
        unparsedPathwayAnswer.question_id || unparsedPathwayAnswer.questionId,
        unparsedPathwayAnswer.question_title || unparsedPathwayAnswer.questionTitle,
        unparsedPathwayAnswer.question_type || unparsedPathwayAnswer.questionType,
        unparsedPathwayAnswer.category_id || unparsedPathwayAnswer.categoryId,
        unparsedPathwayAnswer.value,
        questionType === PathwayQuestionTypes.MULTIPLE
          ? unparsedPathwayAnswer.value.find((answer: any) => Boolean(answer.deniedOptions))
              ?.deniedOptions
          : null,
        unparsedPathwayAnswer.is_prefilled || unparsedPathwayAnswer.isPrefilled,
        unparsedPathwayAnswer.note
      ),
      unparsedPathwayAnswer.order
    );
  }

  static parseSaveCallResponse(response: CallResolutionResponse) {
    response.call = CallsParser.parseCall(response.call);
    return response;
  }
}
