import { AnalyticsService, AnalyticEventName } from '../';

interface Properties {
  suggestion_id: string;
}

export const trackMessageSuggestionsLoadedAnalyticsEvent = ({ suggestion_id }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.LoadedMessageSuggestions,
    properties: { suggestion_id }
  });
};
