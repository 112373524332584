import { FC, FocusEvent, ReactNode } from 'react';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { Testable } from 'utils/TypeUtils';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

interface IProps extends Testable {
  name: string;
  error?: any;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  hint?: string;
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    currentValue: string,
    valueAfterFocus: string
  ) => void;
  onFocus?: () => void;
  className?: string;
  isRequired?: boolean;
  id?: string;
  children?: ReactNode;
  rounded?: boolean;
}

const FormTextAreaField: FC<IProps> = ({
  name,
  error,
  label,
  placeholder = 'Enter text...',
  disabled = false,
  hint,
  testHook,
  onBlur,
  onFocus,
  isRequired,
  className,
  id,
  children,
  rounded
}) => {
  const { register, getValues } = useFormContext();

  return (
    <RHFStyledInput
      className={className}
      placeholder={placeholder}
      type="textarea"
      label={label}
      name={name}
      register={register}
      error={Boolean(error)}
      disabled={disabled}
      hint={hint}
      testHook={testHook}
      onBlur={onBlur}
      onFocus={onFocus}
      getValues={getValues}
      isRequired={isRequired}
      id={id}
      rounded={rounded}
    >
      {children}
    </RHFStyledInput>
  );
};

export default observer(FormTextAreaField);
